import React from "react";
import { withRouter } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
    getAuth,
    updatePassword,
    reauthenticateWithCredential,
    EmailAuthProvider,
  } from 'firebase/auth';
import {initializeApp} from 'firebase/app';
import { collection, query, where, getDocs, getFirestore, doc, setDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { ToastContainer, toast } from "react-toastify";
import { FaRegCopy } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import { AiOutlinePlus } from "react-icons/ai";
import { TfiSave } from "react-icons/tfi";

import user_img from "../../assets/user_img.png";
import profile_humanity from "../../assets/profile_humanity.png";
import profile_location from "../../assets/profile_location.png";
import profile_mail from "../../assets/profile_mail.png";

import Footer from "../../components/Footer/footer";
import "./profile.css";
import "react-toastify/dist/ReactToastify.css";

const firebaseConfig = {
    apiKey: "AIzaSyAg_UV0fIPW5T9fI5Cvu0pZuFc1IM5ugGc",
    authDomain: "icsas-5be17.firebaseapp.com",
    databaseURL: "https://icsas-5be17-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "icsas-5be17",
    storageBucket: "icsas-5be17.appspot.com",
    messagingSenderId: "10086162090",
    appId: "1:10086162090:web:dcf832c98b97a9b226fad3",
    measurementId: "G-S3BVTV1QSC"
  };

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);


class profile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: {},
            firestoreUserData : {},
            isPhotoAvailable: false,
            photoURL: null,
            fullName: "",
            profileView: false,
            passwordView: false,
            walletView: true,
            securityView: false,
            notificationView: false,
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
            email: "",
            ethAddress: "",
            unstoppableAddress:"john.nft",
            slicedWallet: "",
            preferredView : "Profile",
            isSocialAccountConnected: false,
            isGoogleConnected: false,
            isLinkedInConnected: false,
            editInfoView: false,
            image: null,
            imgUrl: null,
            random: Math.floor(Math.random() * 100000000) + 1,
        };
        this.handleOldPassword = this.handleOldPassword.bind(this);
        this.handleName = this.handleName.bind(this);
        this.handleNewPassword = this.handleNewPassword.bind(this);
        this.handleConfirmPassword = this.handleConfirmPassword.bind(this);
        this.toastFunct = this.toastFunct.bind(this);
        this.successToast = this.successToast.bind(this);
        this.profileEditToast = this.profileEditToast.bind(this);
        this.errorToast = this.errorToast.bind(this);
        this.saveData = this.saveData.bind(this);
    }
  async componentDidMount(){
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    auth.onAuthStateChanged((user) => {
        if (user) {
          this.setState({
            userData: user
          })
        }
    });

    var userData = JSON.parse(localStorage.getItem('userData'));
    let email = "";
    let data = {}
    if(userData){
        email= userData.email
        this.setState({
            email: userData.email
        })
    }
    else{
        this.props.history.push("/");
    }
    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);
    const q = query(collection(db, "users"), where("email", "==", email));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      data=doc.data()
        this.setState({
            userData: data,
            photoURL: data.photoURL,
            fullName: data.fullName,
            isGoogleConnected: data.isGoogleConnected
        })
        if(data.isGoogleConnected){
            this.setState({
                isSocialAccountConnected: true
            })
        }
    });
  }
  getData = async () => {
    let docID = ""
    let email = this.state.email
    console.log(email)
    const db = getFirestore(app);
    const q = query(collection(db, "users"), where("email", "==", email));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        docID = doc._document.data.value.mapValue.fields
    });
    console.log(docID)
    this.setState({
        firestoreUserData: docID
    })
  }
  handleOldPassword = (event) => {
    this.setState({
        oldPassword: event.target.value
    });
  }
  handleName = (event) => {
    this.setState({
        fullName: event.target.value
    });
  }
  handleNewPassword = (event) => {
    this.setState({
        newPassword: event.target.value
    });
  }
  handleConfirmPassword = (event) => {
    this.setState({
        confirmPassword: event.target.value
    });
  }
  resetPassword = async () => {
    this.toastFunct();
    const {oldPassword, newPassword, confirmPassword, email} = this.state;
    console.log(oldPassword, newPassword, confirmPassword);
    const credential = EmailAuthProvider.credential(
        email,
        oldPassword
    );
    await reauthenticateWithCredential(auth.currentUser, credential)
    .then((response) => {
        console.log(response);
        this.successToast();
        this.setState({
            oldPassword: "",
            newPassword:"",
            confirmPassword: "",
            passwordView: false
        })
    })
    .catch((error) => {
        console.log("Error IS ====>", error);
        this.errorToast();
    });
    await updatePassword(auth.currentUser, newPassword);
  }
  toastFunct() {
    toast.info("Updating ..", {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });
  }
  successToast() {
    toast.success("Password Updated Successfully", {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });
  }
  profileEditToast() {
    toast.success("Profile Updated Successfully", {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });
  }
  errorToast() {
    toast.error("Auth Error/Wrong Current Password", {
      position: "bottom-center",
      autoClose: 2700,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  warnToast(){
    toast.warn('Wallet address already linked', {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
  }
  saveData = async () => {
    const { image, fullName } = this.state;
    this.toastFunct()
    if(image){
        this.uploadImage();
    }
    let docID = ""
    let email = this.state.userData.email
    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);
    const q = query(collection(db, "users"), where("email", "==", email));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        docID = doc.id
    });
    const userRef = doc(db, "users", docID);
    await setDoc(userRef, { fullName: fullName }, { merge: true });
    this.profileEditToast();
    this.setState({
        editInfoView: false
    })
  }
  handleImage = async (event) => {
    let value = URL.createObjectURL(event.target.files[0]);
    this.setState({
        photoURL: value,
        image: event.target.files[0]
    })
  }
  uploadImage = async() => {
    const app = initializeApp(firebaseConfig);
    const storage = getStorage(app);
    let file = this.state.image;
    let imageName = 'image-' + this.state.random;
    const storageRef = ref(storage, imageName);
    await uploadBytes(storageRef, file)
    .then(() => {
        getDownloadURL(storageRef).then((url)=> {
            this.setState({
                imgUrl: url
            })
            this.updateImageData(url)
        })
    })
  }
  updateImageData = async(url) => {
    const app = initializeApp(firebaseConfig);
    let docID = ""
    let email = this.state.userData.email
    const db = getFirestore(app);
    const q = query(collection(db, "users"), where("email", "==", email));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        docID = doc.id
    });
    console.log(url)
    const userRef = doc(db, "users", docID);
    await setDoc(userRef, { photoURL: url }, { merge: true });
  }
  render() {
    return (
        <>
            <div className="settings_body">
                <p className="settings_header_title">Settings</p>
                <div className="dashboard_domains_tab">
                    {
                        this.state.preferredView === "Profile" ?
                            <button className="settings_domains_tab_active_first">
                                <span className="searchBtn_txt">Profile</span>
                            </button>
                        :
                            <button className="settings_domains_tab_inactive_first">
                                <span className="searchBtn_txt">Profile</span>
                            </button>
                    }
                    {
                        this.state.preferredView === "Notification" ?
                            <button className="settings_domains_tab_active">
                                <span className="searchBtn_txt">Notification</span>
                            </button>
                            :
                            <button className="settings_domains_tab_inactive">
                                <span className="searchBtn_txt">Notification</span>
                            </button>
                    }
                    {
                        this.state.preferredView === "Billing" ?
                            <button className="settings_domains_tab_active">
                                <span className="searchBtn_txt">Account</span>
                            </button>
                            :
                            <button className="settings_domains_tab_inactive">
                                <span className="searchBtn_txt">Account</span>
                            </button>
                    }
                    {
                        this.state.preferredView === "Security" ?
                            <button className="settings_domains_tab_active">
                                <span className="searchBtn_txt">Security</span>
                            </button>
                            :
                            <button className="settings_domains_tab_inactive">
                                <span className="searchBtn_txt">Security</span>
                            </button>
                    }
                </div>
                <Row>
                    <Col md={7}>
                        <div className="profile_left_firstDiv">
                            <Row>
                                <Col md={2}>
                                    {
                                        this.state.photoURL != "" ?
                                            <>
                                                <img src={this.state.photoURL} className="settings_profileImage_empty"/>
                                                {
                                                    this.state.editInfoView ?
                                                    <label id="pic1">
                                                        <input
                                                            type="file"
                                                            name="fileToUpload"
                                                            id="fileToUpload"
                                                            size="1"
                                                            accept="image/*"
                                                            onChange={(event) => {this.handleImage(event)}}
                                                        />
                                                        <center>
                                                            <AiOutlinePlus
                                                                size={18}
                                                                className="AiOutlinePlus-icon2"
                                                            />
                                                        </center>
                                                    </label>
                                                    :
                                                    <></>
                                                }
                                            </>
                                        :
                                            <>
                                                <img src={user_img} className="settings_profileImage_empty"/>
                                                {
                                                    this.state.editInfoView ?
                                                    <label id="pic1">
                                                        <input
                                                            type="file"
                                                            name="fileToUpload"
                                                            id="fileToUpload"
                                                            size="1"
                                                            accept="image/*"
                                                            onChange={(event) => {this.handleImage(event)}}
                                                        />
                                                        <center>
                                                            <AiOutlinePlus
                                                                size={18}
                                                                className="AiOutlinePlus-icon2"
                                                            />
                                                        </center>
                                                    </label>
                                                    :
                                                    <></>
                                                }
                                            </>
                                    } 
                                </Col>
                                <Col md={9}>
                                    {
                                        this.state.editInfoView ?
                                        <>
                                            <input
                                                className="setFullName_settings"
                                                type="text"
                                                placeholder="Enter Full Name"
                                                onChange={(e) => {this.handleName(e)}}
                                                value={this.state.fullName}
                                            />
                                        </>
                                        :
                                        <>
                                        {
                                            this.state.fullName === undefined ?
                                                <p className="settings_fullName">Name not set</p>
                                            :
                                                <p className="settings_fullName">{this.state.fullName}</p>
                                        }
                                        </>
                                    }
                                    
                                    <p className="settings_walletAddress">{this.state.email}<FaRegCopy size={14} className="FaRegCopy-icon" onClick={() => {navigator.clipboard.writeText(this.state.email)}}/></p>
                                </Col>
                                <Col md={1}>
                                    {
                                        this.state.editInfoView ?
                                            <TfiSave size={20} className="FaRegCopy-icon"  onClick={() => this.saveData()}></TfiSave>
                                        :
                                            <FiEdit size={20} className="FaRegCopy-icon" onClick={() => this.setState({editInfoView: true})}></FiEdit>
                                    }
                                
                                </Col>
                            </Row>
                            <p className="profile_bio">Two parts product, one part data, finance to taste. I write about systems, organizational design, and crypto.</p>
                            <hr className="profile_hr"/>
                            <p className="profile_humanityTxt"><img src={profile_humanity} className="profile_humanity"/>User Verified</p>
                            <p className="profile_humanityTxt_black"><img src={profile_mail} className="profile_humanity"/>+91-8794803021</p>
                            <p className="profile_humanityTxt_black"><img src={profile_location} className="profile_humanity"/>Kohima, Nagaland - India</p>
                        </div>
                        <div className="profile_left_firstDiv">
                            <p className="settings_TabsTittle">Study Resources</p>
                            <p className="profile_bio">Get e-book on Digital Literacy by NIELIT Kohima to learn the latest trends in digitalization.</p>
                            <div className="profile_skyblueBtn">
                                <p className="profile_skyblueBtn_txt">Download PDF</p>
                            </div>
                        </div>
                        <div className="profile_left_firstDiv">
                            <p className="settings_TabsTittle">Two Factor Authentication</p>
                            <p className="profile_bio">Enabling two factor authentication helps keep your domains safe.</p>
                            <div className="profile_skyblueBtn">
                                <p className="profile_skyblueBtn_txt">Setup 2FA</p>
                            </div>
                        </div>
                    </Col>
                    <Col md={5}>
                        <div className="profile_left_firstDiv">
                            <p className="settings_TabsTittle">Account</p>
                            {
                                this.state.isGoogleConnected ?
                                <p className="profile_bio">Reset Password unavailable since you signed up with Google.</p>
                                :
                                <></>
                            }
                            {
                                this.state.isSocialAccountConnected ?
                                <></>
                                :
                                <p className="profile_bio">Here you can edit public information about yourself. The changes will be displayed for others within 5 minutes.</p>
                            }
                            {
                                this.state.passwordView ?
                                    <>
                                        <p className="oldPasswordInput_labels">Old Password</p>
                                        <input
                                            className="oldPasswordInput"
                                            type="password"
                                            placeholder="Enter last used password"
                                            onChange={(e) => {this.handleOldPassword(e)}}
                                            value={this.state.oldPassword}
                                        />
                                        <p className="oldPasswordInput_labels">New Password</p>
                                        <input
                                            className="oldPasswordInput"
                                            type="password"
                                            placeholder="Enter new password"
                                            onChange={(e) => {this.handleNewPassword(e)}}
                                            value={this.state.newPassword}
                                        />
                                        <p className="oldPasswordInput_labels">Confirm Password</p>
                                        <input
                                            className="oldPasswordInput"
                                            type="password"
                                            placeholder="Confirm new password"
                                            onChange={(e) => {this.handleConfirmPassword(e)}}
                                            value={this.state.confirmPassword}
                                        />
                                        <div className="settings_save_btn" onClick={() => this.resetPassword()}>
                                            <p className="profile_save_btn_txt">Save changes</p>
                                        </div>
                                    </>
                                :
                                    <>
                                    {
                                        this.state.isSocialAccountConnected ?
                                        <div className="profile_skyblueBtn_disabled">
                                            <p className="profile_skyblueBtn_txt_disabled">Reset Password</p>
                                        </div>
                                        :
                                        <div className="profile_skyblueBtn" onClick={() => this.setState({passwordView: true})}>
                                            <p className="profile_skyblueBtn_txt">Reset Password</p>
                                        </div>
                                    }
                                    </>
                                    
                            }
                        </div>
                        <div className="profile_left_firstDiv">
                            <p className="settings_TabsTittle">Artisian Documents</p>
                            <p className="profile_bio">You haven’t upload documents yet. Please upload the required documents for further verification.</p>
                            <div className="profile_skyblueBtn">
                                <p className="profile_skyblueBtn_txt">Upload Documents</p>
                            </div>
                        </div>
                    </Col>
                </Row>
                <ToastContainer
                    position="bottom-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </div>
            <Footer/>
        </>
    );
  }
}

export default withRouter(profile);
