import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Navbar from './components/Navbar/index';
import home from "./pages/home/home";
import dashboard from "./pages/dashboard/dashboard";
import about from "./pages/about/about";
import media from "./pages/media/media";
import profile from "./pages/profile/profile";
import privacy_policy from "./pages/policies/privacyPolicy";
import account_deletion from "./pages/policies/accountDeletion";

import 'bootstrap/dist/css/bootstrap.min.css';

class App extends Component {
  render() {
    return (
      <Router>
        <Navbar />
        <Switch>
          <Route exact path="/" component={home} />
          <Route path="/dashboard" component={dashboard} />
          <Route path="/about" component={about} />
          <Route path="/media" component={media} />
          <Route path="/profile" component={profile} />
          <Route path="/privacy-policy" component={privacy_policy} />
          <Route path="/account-deletion" component={account_deletion} />
        </Switch>
      </Router>
    );
  }
}

export default App;