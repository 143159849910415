import React from "react";
import { Nav, NavLink, NavMenu } from "./NavbarElements";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SideBar from "../Sidebar/sidebar";
import { FaApple } from "react-icons/fa";
import { BiLogoPlayStore } from "react-icons/bi";

//images
import sidebar_icon from "../../assets/icon/sidebar_icon.png";
import register from "../../assets/register.png";
import login from "../../assets/login.png";
import logout from "../../assets/logout.png";
import nielit_logo from "../../assets/nielit_logo.png";
import nielit_logo_pc from "../../assets/nielit_logo_pc.png";
import icsas_logo from "../../assets/icsas_logo.png";
import meity_logo_pc from "../../assets/meity_logo_pc.png";
import RegistrationFormPDF from '../../assets/RegistrationForm.pdf';

const styles = {
  row: {
      marginLeft: 0,
      marginRight: 0
  },
  col: {
      paddingLeft: 0,
      paddingRight: 0
  }
};

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sideBar: false,
      dropdownView: false,
      notificationModal: false,
      isLoggedIn: false
    };
  }
  componentDidMount(){
    var userData = JSON.parse(localStorage.getItem('userData'));
    if(userData){
        this.setState({
          isLoggedIn: true
        })
    }
  }
  clearAsyncStorage = async() => {
    localStorage.clear();
    window.location.reload()
  }
  render() {
    return (
      <>
        <div className="logos-div-navbar">
          <img
            alt="logo"
            src={nielit_logo}
            className="nielit-logo-navbar"
          />
          <img
            alt="logo"
            src={nielit_logo_pc}
            className="nielit-logo-navbar_pc"
          />
          <img
            alt="logo"
            src={icsas_logo}
            className="icsas-logo-navbar"
          />
          <img
            alt="logo"
            src={meity_logo_pc}
            className="meity-logo-navbar"
          />
        </div>
        <Nav>
        <div className="side-bar">
            {this.state.sideBar ? (
              <Row style={styles.row} className="SideBarPosition">
                <Col onClick={() => this.setState({ sideBar: false })} style={styles.col}>
                  <SideBar sideBarShow={this.state.sideBar}/>
                </Col>
                <Col onClick={() => this.setState({ sideBar: false })} style={styles.col}>
                  <div className="sidebar_Shadow">
                    &nbsp;
                  </div>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col>
                  <div className="mobile-side-img-div2">
                    <img
                        alt="sidebar"
                        src={sidebar_icon}
                        className="sidebar-icon"
                        onClick={() => this.setState({ sideBar: true })}
                      />
                  </div>
                </Col>
              </Row>
            )}
          </div>
          <NavMenu>
          {
              this.state.dropdownView?
                <div className="categories-hover-div" onMouseOver={() => this.setState({dropdownView: true})}>
                  <center>
                    <NavLink
                        to="/feedback"
                        className="nav-change-drop"
                        onMouseOver={() => this.setState({dropdownView: true})}
                      >
                        Feedbacks
                    </NavLink>
                    <div style={{marginTop: 15, marginBottom:15}}></div>
                    <p
                        className="nav-change-drop"
                        onClick={() => this.setState({notificationModal: true})}
                        onMouseOut={() => this.setState({dropdownView: false})}
                    >
                        Notifications
                    </p>
                  </center>
                </div>
              :
              <></>
            }
            <NavLink to="/" style={{marginLeft:"3%",marginRight:"2.7%"}} onMouseOver={() => this.setState({dropdownView: false})}>
              <span className="contact-nav-text">Home</span>
            </NavLink>
            <NavLink to="/dashboard" style={{marginRight:"2.7%"}} onMouseOver={() => this.setState({dropdownView: false})}>
              <span className="contact-nav-text">Dashboard</span>
            </NavLink>
            <NavLink to="/about" style={{marginRight:"2.7%"}} onMouseOver={() => this.setState({dropdownView: false})}>
              <span className="contact-nav-text">About</span>
            </NavLink>
            <NavLink to="/media" style={{marginRight:"2.7%"}} onMouseOver={() => this.setState({dropdownView: false})}>
              <span className="contact-nav-text">Media</span>
            </NavLink>
          </NavMenu>
          <div className="navbar-register">
            <Row onClick={()=> window.open("https://play.google.com/store/apps/details?id=com.nielitkma.icsas", '_blank')}>
              <Col md={2}>
                <BiLogoPlayStore size={25} color={"#fff"}/>
              </Col>
              <Col md={9}>
                <p className="navbar_txt">Playstore</p>
              </Col>
            </Row>
            <Row style={{marginLeft:"12%"}} onClick={()=> window.open("https://testflight.apple.com/join/yLF76qYw", '_blank')}>
            <Col md={2}>
                <FaApple size={25} color={"#fff"}/>
              </Col>
              <Col md={9}>
                <p className="navbar_txt">AppStore</p>
              </Col>
            </Row>
          </div>
        </Nav>
      </>
    );
  }
}

export default Navbar;
