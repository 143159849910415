import React from "react";
import { withRouter } from "react-router-dom";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { FaApple } from "react-icons/fa";
import { BiLogoPlayStore } from "react-icons/bi";
import { BsBoxSeam, BsPersonCircle, BsInfoCircle } from "react-icons/bs";

import { RxDashboard } from "react-icons/rx";
import { IoHomeOutline, IoCreateOutline } from "react-icons/io5";
import { IoMdImages } from "react-icons/io";
import { SlLogin } from "react-icons/sl";
import { VscFeedback } from "react-icons/vsc";

import handloom_logo from "../../assets/handloom_logo.png";
import "./custom.scss";
import "./sidebar_styles.css";


const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    paritialVisibilityGutter: 0
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    paritialVisibilityGutter: 75
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    paritialVisibilityGutter: 75
  }
};

class SideBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sideBar: this.props.sideBarShow
    };
  }
  toggleButton = () => {
    this.setState({
      sideBar: !this.state.sideBar,
    });
  };
  navigateTo = (pageName) => {
    this.props.history.push(pageName);
    this.toggleButton()
  }
  navigateToEcommerce = () => {
    window.open("https://ecommerce.nielitdihh.in/", '_blank')
    this.toggleButton()
  }
  navigatePlaystore = () => {
    window.open("https://play.google.com/store/apps/details?id=com.nielitkma.icsas", '_blank');
    this.toggleButton()
  }
  navigateAppstore = () => {
    window.open("https://testflight.apple.com/join/yLF76qYw", '_blank');
    this.toggleButton()
  }
  render() {
    return (
      <>
        {
          this.state.sideBar?
            <ProSidebar>
              <center>
                <img src={handloom_logo} style={{ height: 120, marginTop:"17%", marginBottom: "10%" }} />
              </center>
              <Menu iconShape="square" className="sidebar-icons-margin">
                <MenuItem icon={<IoHomeOutline size={26} color={"#fff"} style={{marginRight: 7}}/>}>
                  <span onClick={() => this.navigateTo("/")} className="sidebar-item-txt">Home</span>
                </MenuItem>
                <MenuItem icon={<BsInfoCircle size={26} color={"#fff"} style={{marginRight: 7}}/>}>
                  <span onClick={() => this.navigateTo("/about")} className="sidebar-item-txt">About</span>
                </MenuItem>
                <MenuItem icon={<RxDashboard size={25} color="white" style={{marginRight: 7}}/>}>
                  <span onClick={() => this.navigateTo("/dashboard")} className="sidebar-item-txt">Dashboard</span>
                </MenuItem>
                <MenuItem icon={<IoMdImages size={27} color={"#fff"} style={{marginRight: 7}}/>}>
                  <span onClick={() => this.navigateTo("/media")} className="sidebar-item-txt">Media</span>
                </MenuItem>
                <center>
                <hr className="sidebar_hr_white"/>
                </center>
                <MenuItem icon={<BiLogoPlayStore size={23} color={"#fff"} style={{marginRight: 7}}/>}>
                  <span onClick={this.navigatePlaystore} className="sidebar-item-txt">Playstore</span>
                </MenuItem>
                <MenuItem icon={<FaApple size={25} color={"#fff"} style={{marginRight: 7}}/>}>
                  <span onClick={this.navigateAppstore} className="sidebar-item-txt">AppStore</span>
                </MenuItem>
                <div className="empty-sidebar-div"></div>
              </Menu>
            </ProSidebar>
          :
            <></>
        }
      </>
    );
  }
}

export default withRouter(SideBar);
