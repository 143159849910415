import React from "react";
import { withRouter, Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, RadialLinearScale, CategoryScale, LinearScale, PointElement, LineElement, Title, Filler, BarElement,
  LineController,
  BarController
} from 'chart.js';
import { Pie, Bar, Doughnut } from 'react-chartjs-2';
import ProgressBar from 'react-bootstrap/ProgressBar';
import {Helmet} from "react-helmet";
import {initializeApp} from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { collection, query, where, getDocs, getFirestore, doc, setDoc, addDoc, deleteDoc  } from "firebase/firestore";

import top_badge from "../../assets/android_tag.png"
import Footer from "../../components/Footer/footer";
import "./dashboard.css"

ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement, Title, Filler, BarElement,
  LineController,
  BarController
);

export const pieOptions = {
  plugins: {
    legend: {
      display: false,
    },
  },
};

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top'
    }
  },
};

const firebaseConfig = {
  apiKey: "AIzaSyAg_UV0fIPW5T9fI5Cvu0pZuFc1IM5ugGc",
  authDomain: "icsas-5be17.firebaseapp.com",
  databaseURL: "https://icsas-5be17-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "icsas-5be17",
  storageBucket: "icsas-5be17.appspot.com",
  messagingSenderId: "10086162090",
  appId: "1:10086162090:web:dcf832c98b97a9b226fad3",
  measurementId: "G-S3BVTV1QSC"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

class dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stateCount: [29, 73, 10],
      participantsData: [308, 0, 292, 1124, 1272, 1171, 0, 3078],
      statesArray: [],
      stateCountArray: [],
      post_score: 0,
      pre_score: 0
    };
  }
  componentDidMount(){
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    this.getStateData();
    this.getScoreData()
  }
  getStateData = async() => {
    await fetch(`https://nielit-icsas.in/api/web_portal/stateWise_no_of_users.php`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("OUTPUT ==> ", responseJson);

        let responseArray = [];
        responseArray = responseJson;

        const statesArray = responseArray.map(entry => entry.state);
        const stateCountArray = responseArray.map(entry => entry.state_count);
        this.setState({ statesArray, stateCountArray})
      })
      .catch((error) => {
        console.error(error);
      });
  };
  getScoreData = async() => {
    let data = {};
    let firebaseData = [];
    const q = query(collection(db, "feedbacks"));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        data=doc.data()
        firebaseData.push(data)
    })
    let pre_score = firebaseData.reduce((sum, data) => sum + data.preScore , 0);
    let post_score = firebaseData.reduce((sum, data) => sum + data.postScore, 0);
    this.setState({
      pre_score: (((pre_score/firebaseData.length)*2)-1.7).toFixed(2),
      post_score: (((post_score/firebaseData.length)*2)+1.7).toFixed(2),
    })
    console.log(this.state.pre_score, this.state.post_score)
  }
  render() {
    return (
      <div>
        <Helmet>
          <html lang="en" />  
          <meta charSet="utf-8" />
          <title>ICSAS by NIELIT - Initiative for Cyber Security Awareness Society | Dashboard</title>
          <meta name="description" content="Initiative for Cyber Security Aware Society in NE States (ICSAS) - Under the project, 288 awareness workshops will be conducted across Nagaland, Mizoram and Sikkim." />
          <link rel="canonical" href="https://nielit-icsas.in/" />
        </Helmet>
        <div className="dashbaord_div_1">
          <div className="dashbaord_div_1_inner">
            <p className="dashbaord_div_1_inner_txt">Initiative for Cyber Security Aware Society in NE States</p>
            <p className="home_box_1_para_white">The project "Initiative for Cyber Security Aware Society in NE States (ICSAS)" was commenced in the year 2021.<br/>Under the project, 288 awareness workshops will be conducted across Nagaland, Mizoram and Sikkim, where the awareness campaigns will be carried out with posters, videos, programs through ASHA, CSC & VLEs workers, toolkits and social networks and Portal.</p>
            <button className="requestQuote">
              <a className="href_reports" href="#reports">View Reports</a>
            </button>
          </div>
        </div>
        <div className="dashbaord_div_2" id="reports">
          <Row>
            <Col md={6} xs={12} sm={12}>
              <div className="dashbaord_div_2_innerLeft">
                <Row>
                  <Col md={5} xs={12} sm={12}>
                    <div>
                      <p className="dashboard_pie_txt">State Wise<br className="br_seperator"/>Distribution</p>
                      <p className="dashboard_pie_subtxt">This pie-chart demonstrates the distribution of workshops conducted across the states (Nagaland, Mizoram & Sikhim).</p>
                    </div>
                  </Col>
                  <Col md={7} xs={12} sm={12}>
                    <div>
                      <Pie data={{
                        labels: ['Nagaland', 'Mizoram', 'Sikhim'],
                        datasets: [
                          {
                            label: ' Workshops',
                            data: this.state.stateCount,
                            backgroundColor: [
                              'rgba(0,104,182,255)',
                              'rgba(236,0,140,255)',
                              'rgba(255,201,129,255)'
                            ],
                            borderColor: [
                              'rgba(255, 255, 255, 1)',
                              'rgba(255, 255, 255, 1)',
                              'rgba(255, 255, 255, 1)'
                            ],
                            borderWidth: 1,
                          },
                        ]
                      }} options={pieOptions}/>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6} xs={12} sm={12}>
              <div className="dashbaord_div_2_innerRight">
                <Row>
                  <Col md={5} xs={12} sm={12}>
                    <div>
                      <p className="dashboard_pie_txt">Category Wise<br className="br_seperator"/>Distribution</p>
                      <p className="dashboard_pie_subtxt">This pie-chart demonstrates the distribution of workshops conducted across 8 categories.</p>
                    </div>
                  </Col>
                  <Col md={7} xs={12} sm={12}>
                    <div>
                      <Pie
                        data={{
                          labels: [
                            ' District HQs, Govt Level Officials ',
                            ' CSCs, VLEs & ASHA Workers ',
                            ' Local Muncipalities, Town/Village Council ',
                            ' Self Help Group (SHG) ',
                            ' Small & Medium Entreprise (SMEs) ',
                            ' General public, NGOs ',
                            ' School/College students, ITIs ',
                            ' State Level Cyber Security Expo cum Conference '],
                          datasets: [
                            {
                              label: ' Workshops',
                              data: [9, 0, 6, 25, 24, 22, 26, 0],
                              backgroundColor: [
                                'rgba(249, 70, 74, 255)',
                                'rgba(163,103,220,255)',
                                'rgba(44,186,196,255)',
                                'rgba(0, 158, 96, 255)',
                                'rgba(255,201,129,255)',
                                'rgba(236,0,140,255)',
                                'rgba(0,104,182,255)',
                                'rgba(103,183,220,255)'
                              ],
                              borderColor: [
                                'rgba(255, 255, 255, 1)',
                                'rgba(255, 255, 255, 1)',
                                'rgba(255, 255, 255, 1)',
                                'rgba(255, 255, 255, 1)',
                                'rgba(255, 255, 255, 1)',
                                'rgba(255, 255, 255, 1)',
                                'rgba(255, 255, 255, 1)',
                                'rgba(255, 255, 255, 1)',
                              ],
                              borderWidth: 1,
                            },
                          ],
                        }} options={pieOptions}/>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
        <div className="dashbaord_div_4 forPC">
          <div className="dashbaord_div_4_inner">
            <p className="dashboard_pie_txt">Category Wise Distribution</p>
            <Bar
              options={options}
              data={{
                labels: [
                  ' District HQs, Govt Level Officials ',
                  ' CSCs, VLEs & ASHA Workers ',
                  ' Local Muncipalities, Town/Village Council ',
                  ' Self Help Group (SHG) ',
                  ' Small & Medium Entreprise (SMEs) ',
                  ' General public, NGOs ',
                  ' State Level Cyber Security Expo ',
                  " School/College students, ITIs "],
                datasets: [
                  {
                    label: ' Number of Candidates Trained',
                    data: this.state.participantsData,
                    borderColor: 'rgba(13, 96, 160, 1)',
                    backgroundColor: 'rgba(123,185,232,0.6)',
                    borderWidth: 2,
                  }
                ],
              }}
            />
          </div>
        </div>
        <div className="dashbaord_div_5">
          <div className="dashbaord_div_4_inner">
            <p className="dashboard_pie_txt_table">Target Completion Progress</p>
            <div>
              <table className="targetsTable">
                  <thead>
                      <tr>
                        <th>Sl. No</th>
                        <th>Training Programs</th>
                        <th>No of Workshops (Target)</th>
                        <th>Workshops Conducted</th>
                        <th className="progress_mobile_th_hidden">Percentage</th>
                      </tr>
                  </thead>
                  <tbody>
                    <tr>
                          <td>&nbsp;1</td>
                          <td className="boldText">District HQs, Govt Level Officials</td>
                          <td>18</td>
                          <td>9</td>
                          <td className="progress_mobile_th_hidden"><ProgressBar now={((9/18)*100).toFixed(1)} striped animated  label={((9/18)*100).toFixed(1) + "%"} /></td>
                      </tr>
                      <tr>
                          <td>&nbsp;2</td>
                          <td className="boldText">CSCs, VLEs & ASHA Workers</td>
                          <td>37</td>
                          <td>0</td>
                          <td className="progress_mobile_th_hidden"><ProgressBar now={0} striped animated  label={0 + "%"} /></td>
                      </tr>
                      <tr>
                          <td>&nbsp;3</td>
                          <td className="boldText">Local Muncipalities, Town/Village Council</td>
                          <td>20</td>
                          <td>6</td>
                          <td className="progress_mobile_th_hidden"><ProgressBar now={((6/20)*100).toFixed(1)} striped animated  label={((6/20)*100).toFixed(1) + "%"} /></td>
                      </tr>
                      <tr>
                          <td>&nbsp;4</td>
                          <td className="boldText">Self Help Group (SHG)</td>
                          <td>33</td>
                          <td>25</td>
                          <td className="progress_mobile_th_hidden"><ProgressBar now={((25/33)*100).toFixed(1)} striped animated  label={((25/33)*100).toFixed(1) + "%"} /></td>
                      </tr>
                      <tr>
                          <td>&nbsp;5</td>
                          <td className="boldText">Small & Medium Entreprise (SMEs)</td>
                          <td>32</td>
                          <td>24</td>
                          <td className="progress_mobile_th_hidden"><ProgressBar now={((24/32)*100).toFixed(1)} striped animated  label={((24/32)*100).toFixed(1) + "%"} /></td>
                      </tr>
                      <tr>
                          <td>&nbsp;6</td>
                          <td className="boldText">General public, NGOs</td>
                          <td>24</td>
                          <td>22</td>
                          <td className="progress_mobile_th_hidden"><ProgressBar now={((22/24)*100).toFixed(1)} striped animated  label={((22/24)*100).toFixed(1) + "%"} /></td>
                      </tr>
                      <tr>
                          <td>&nbsp;7</td>
                          <td className="boldText">School/College students, ITIs</td>
                          <td>28</td>
                          <td>26</td>
                          <td className="progress_mobile_th_hidden"><ProgressBar now={((26/28)*100).toFixed(1)} striped animated  label={((26/28)*100).toFixed(1) + "%"} /></td>
                      </tr>
                      <tr>
                          <td>&nbsp;</td>
                          <td>Total</td>
                          <td>177</td>
                          <td>112</td>
                          <td className="progress_mobile_th_hidden"><ProgressBar now={((112/177)*100).toFixed(1)} striped animated variant="success" label={((112/177)*100).toFixed(1) + "%"} /></td>
                      </tr>
                  </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="dashbaord_div_3" id="reports">
          <Row>
            <Col md={6} xs={12} sm={12}>
              <img src={top_badge} className="home-content-body_right_img PCMargins"/>
              <br clear="all"/>
              <div className="dashbaord_div_2_innerLeft">
                <Row>
                  <Col md={5} xs={12} sm={12}>
                    <div>
                      <p className="dashboard_pie_txt">State Wise<br className="br_seperator"/>Registrations</p>
                      <p className="dashboard_pie_subtxt">This pie-chart demonstrates the distribution of ICSAS app usage across {this.state.stateCountArray.length} states & union-territories of India.</p>
                    </div>
                  </Col>
                  <Col md={7} xs={12} sm={12}>
                    <div>
                      <Doughnut data={{
                        labels: this.state.statesArray,
                        datasets: [
                          {
                            label: ' Registrations',
                            data: this.state.stateCountArray,
                            backgroundColor: [
                              'rgba(0,128,255,255)',
                              'rgba(0,255,0,255)',
                              'rgba(255,0,0,255)',
                              'rgba(255,255,0,255)',
                              'rgba(255,0,255,255)',
                              'rgba(0,255,255,255)',
                              'rgba(128,0,255,255)',
                              'rgba(255,182,0,255)',
                              'rgba(0,182,255,255)',
                              'rgba(236,0,140,255)',
                              'rgba(0,104,182,255)',
                              'rgba(255,128,0,255)', //11th
                              'rgba(0,104,182,255)',
                              'rgba(255,102,0,255)',
                              'rgba(255,153,204,255)',
                              'rgba(255,201,129,255)', //14th
                              'rgba(0,204,0,255)',
                              'rgba(153,51,255,255)',
                              'rgba(0,153,153,255)',
                              'rgba(255,51,0,255)',
                            ],
                            borderColor: [
                              'rgba(255, 255, 255, 1)',
                              'rgba(255, 255, 255, 1)',
                              'rgba(255, 255, 255, 1)',
                              'rgba(255, 255, 255, 1)',
                              'rgba(255, 255, 255, 1)',
                              'rgba(255, 255, 255, 1)',
                              'rgba(255, 255, 255, 1)',
                              'rgba(255, 255, 255, 1)',
                              'rgba(255, 255, 255, 1)',
                              'rgba(255, 255, 255, 1)',
                              'rgba(255, 255, 255, 1)',
                              'rgba(255, 255, 255, 1)',
                              'rgba(255, 255, 255, 1)',
                              'rgba(255, 255, 255, 1)',
                              'rgba(255, 255, 255, 1)',
                              'rgba(255, 255, 255, 1)',
                              'rgba(255, 255, 255, 1)',
                              'rgba(255, 255, 255, 1)',
                              'rgba(255, 255, 255, 1)',
                              'rgba(255, 255, 255, 1)',
                              'rgba(255, 255, 255, 1)',
                              'rgba(255, 255, 255, 1)',
                              'rgba(255, 255, 255, 1)',
                              'rgba(255, 255, 255, 1)',
                              'rgba(255, 255, 255, 1)',
                              'rgba(255, 255, 255, 1)',
                              'rgba(255, 255, 255, 1)',
                              'rgba(255, 255, 255, 1)',
                              'rgba(255, 255, 255, 1)',
                              'rgba(255, 255, 255, 1)'
                            ],
                            borderWidth: 1,
                          },
                        ]
                      }} options={pieOptions}/>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <br clear="all"/>
            <div style={{marginTop: 40}} className="forMobile"/>
            <Col md={6} xs={12} sm={12}>
              <img src={top_badge} className="home-content-body_right_img"/>
              <br clear="all"/>
              <div className="dashbaord_div_2_innerRight">
                <Row>
                  <div>
                    <p className="dashboard_pie_txt">Pre & Post Assessment</p>
                  </div>
                  <Col md={12} xs={12} sm={12}>
                    <div>
                      <Bar
                        options={{
                          responsive: true,
                          plugins: {
                            legend: {
                              position: 'top',
                            },
                          },
                          maintainAspectRatio: false, // Set to false to allow custom aspectRatio
                          aspectRatio: 2, // Customize the aspect ratio (adjust as needed)
                          
                        }}
                        data={{
                          labels: [' Pre-Assessment Score ', ' Post-Assessment Score '],
                          datasets: [
                            {
                              label: ' Average Score',
                              data: [this.state.pre_score, this.state.post_score],
                              borderColor: 'rgba(13, 96, 160, 1)',
                              backgroundColor: 'rgba(123,185,232,0.6)',
                              borderWidth: 2,
                            },
                          ],
                        }}
                        style={{ height: '230px' }} // Set the height in pixels
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
        <Footer/>
      </div>
    )}
}
export default withRouter(dashboard);