import React, {useState, useEffect} from 'react'
import { GoogleMap, useJsApiLoader, Marker, InfoWindow, } from '@react-google-maps/api';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FaPhoneAlt } from "react-icons/fa";

let containerStyle = {
  width: '101.5%',
  height: '100%',
  margin: 0
};

function MapComponent() {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyBttNovQQfTde-VkUHOugPWUrgr9DkmmaU"
  })

  const [mapRef, setMapRef] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [map, setMap] = React.useState(null);
  const [zoom, setZoom] = React.useState(5);
  const [infoWindowData, setInfoWindowData] = useState();
  const [markers, setMarkers] = useState([
    {centerName: "NIELIT Kohima", lat: 25.7227876, lng: 94.0854124, address: "High Court Junction", city: "Kohima, Nagaland - 797004", phone: "+919436215243"},
    {centerName: "NIELIT Aizawl", lat: 23.7554686, lng: 92.7368523, address: "Industrial Estate, Zuangtui", city: "Aizawl, Mizoram - 796017", phone: "03892350581"},
    {centerName: "NIELIT Gangtok", lat: 27.3352335, lng: 88.6091579, address: "Upper Sichey, Arithang", city: "Gangtok, Sikkim - 737101", phone: "+919436142955"}
  ]);
  const [position, setPosition] = useState({
    lat: 26.2,
    lng: 91.227569
  });
  useEffect(() => {
    if(window.innerWidth<700){
      containerStyle = {
        width: '100%',
        height: '100%',
        margin: 0
      };
    }
    else{
      containerStyle = {
        width: '100%',
        height: '100%',
        margin: 0
      }
    }
  }, []);

  const onLoad = React.useCallback(function callback(map) {
    setZoom(7)
    map.setZoom(zoom)
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
    setZoom(7)
  }, [])
  
  const handleMarkerClick = (id, lat, lng, centerName) => {
    mapRef?.panTo({ lat, lng });
    setInfoWindowData({ id, centerName });
    setIsOpen(true);
  };
  const mapOptions = {
    zoom: 7,
    styles: [
      {
        featureType: 'poi',
        stylers: [{ visibility: 'off' }],
      },
    ],
  };
  return isLoaded ? (
    <>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={position}
        onLoad={onLoad}
        options={mapOptions}
        zoom={zoom}
        onUnmount={onUnmount}
      >
        <>
          {markers.map(({ centerName, lat, lng, address, city, phone }, key) => (
            <>
              {
                markers.length > 0 ?
                <>
                <Marker
                    key={key}
                    position={{ centerName, lat, lng }}
                    icon={{
                      url: "https://i.ibb.co/vPFMXV4/dihh-pin.png", // Path to your custom icon image
                      scaledSize: new window.google.maps.Size(55, 55), // Set the desired width and height of the icon
                      origin: new window.google.maps.Point(0, 0),
                      anchor: new window.google.maps.Point(20, 40), // Center the icon at the specified position
                    }}
                    onClick={() => {
                      handleMarkerClick(key, lat, lng, centerName);
                    }}
                  >
                    {isOpen && infoWindowData?.id === key && (
                      <InfoWindow
                        options= {{minWidth : 230}}
                        onCloseClick={() => {
                          setIsOpen(false);
                        }}
                      >
                        <div style={{ position:"absolute", top: 5, bottom: 5}}>
                          <p className='markerTxt'>{centerName}</p>
                          <p className='markerSubTxt'>{city}</p>
                          
                          <Row style={{marginTop: 5}}>
                            <Col md={1} xs={1} sm={1}>
                              <FaPhoneAlt
                                size={12}
                                className="loc-icons_marker"
                              />
                            </Col>
                            <Col md={5} xs={10} sm={10}>
                              <a href={"tel:" + phone}>
                                <p className="contact-div1-subtitle-marker number">{phone}</p>
                              </a>
                            </Col>
                          </Row>
                        </div>
                      </InfoWindow>
                    )}
                  </Marker>
                </>
                :
                <></>
              }
            </>
          ))}
        </>
      </GoogleMap>
    </>
    
  ) : <></>
}

export default React.memo(MapComponent)