import React from "react";
import { withRouter, Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Modal, ModalBody, } from 'reactstrap';
import {Helmet} from "react-helmet";
import Carousel from 'react-multi-carousel';

import DIET_Sikkim_1 from "../../assets/DIET_Sikkim_1.jpeg";
import DIET_Sikkim_2 from "../../assets/DIET_Sikkim_2.jpeg";
import DIET_Sikkim_3 from "../../assets/DIET_Sikkim_3.jpeg";
import DIET_Sikkim_4 from "../../assets/DIET_Sikkim_4.jpeg";

import DIET2_Sikkim_1 from "../../assets/DIET2_Sikkim_1.jpeg";
import DIET2_Sikkim_2 from "../../assets/DIET2_Sikkim_2.jpeg";
import DIET2_Sikkim_3 from "../../assets/DIET2_Sikkim_3.jpeg";


import Govt_Employee_1 from "../../assets/Govt_Employee_1.jpg";
import Govt_Employee_2 from "../../assets/Govt_Employee_2.jpg";


import Students_1 from "../../assets/Students_1.jpeg";
import Students_2 from "../../assets/Students_2.jpeg";


import SHG_1 from "../../assets/SHG_1.jpeg";
import SHG_2 from "../../assets/SHG_2.jpeg";


import SME_1 from "../../assets/SME_1.jpeg";
import SME_2 from "../../assets/SME_2.jpeg";

import Kiphire_Govt_Employees_1 from "../../assets/Kiphire_Govt_Employees_1.JPG";
import Kiphire_Govt_Employees_2 from "../../assets/Kiphire_Govt_Employees_2.jpg";
import Kiphire_Govt_Employees_3 from "../../assets/Kiphire_Govt_Employees_3.jpg";

import Zunheboto_School_1 from "../../assets/Zunheboto_School_1.JPG";
import Zunheboto_School_2 from "../../assets/Zunheboto_School_2.JPG";
import Zunheboto_School_3 from "../../assets/Zunheboto_School_3_BG.JPG";

import Peren_SME from "../../assets/Peren_SME.JPG";

import Kiphire_School_1 from "../../assets/Kiphire_School_1.JPG";
import Kiphire_School_2 from "../../assets/Kiphire_School_2.JPG";
import Kiphire_School_3 from "../../assets/Kiphire_School_3.JPG";

import NGO_1 from "../../assets/NGO_1.jpg";
import NGO_2 from "../../assets/NGO_2.jpg";

import "./media.css";
import Footer from "../../components/Footer/footer";
import 'react-multi-carousel/lib/styles.css';

const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    }
  };

class media extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentEvent: "DIET_1_Event",
      imageModal: false
    };
  }
  componentDidMount(){
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }
  render() {
    return (
      <div className="container-box">
        <Modal
          isOpen={this.state.imageModal} toggle={() => this.setState({imageModal: false})} size="xl" className="border-radius-10"
        >
            <ModalBody>
                <div className="modalImage_div">
                    {
                        this.state.currentEvent === "DIET_1_Event" ?
                        <>
                            <Carousel
                                autoPlay={true}
                                autoPlaySpeed={2500}
                                infinite={true}
                                arrows={true}
                                swipeable={false}
                                draggable={false}
                                removeArrowOnDeviceType={["mobile"]}
                                responsive={responsive}
                            >
                                <img src={DIET_Sikkim_1} alt="Notebook" className="media_carousel_images"/>
                                <img src={DIET_Sikkim_2} alt="Notebook" className="media_carousel_images"/>
                                <img src={DIET_Sikkim_3} alt="Notebook" className="media_carousel_images"/>
                                <img src={DIET_Sikkim_4} alt="Notebook" className="media_carousel_images"/>
                            </Carousel> 
                        </>
                    :
                        <></>
                    }
                    {
                        this.state.currentEvent === "DIET_2_Event" ?
                        <>
                            <Carousel
                                autoPlay={true}
                                autoPlaySpeed={2500}
                                infinite={true}
                                arrows={true}
                                swipeable={false}
                                draggable={false}
                                removeArrowOnDeviceType={["mobile"]}
                                responsive={responsive}
                            >
                                <img src={DIET2_Sikkim_1} alt="Notebook" className="media_carousel_images"/>
                                <img src={DIET2_Sikkim_2} alt="Notebook" className="media_carousel_images"/>
                                <img src={DIET2_Sikkim_3} alt="Notebook" className="media_carousel_images"/>
                            </Carousel> 
                        </>
                    :
                        <></>
                    }
                    {
                        this.state.currentEvent === "Govt_Employees_Event" ?
                        <>
                            <Carousel
                                autoPlay={true}
                                autoPlaySpeed={2500}
                                infinite={true}
                                arrows={true}
                                swipeable={false}
                                draggable={false}
                                removeArrowOnDeviceType={["mobile"]}
                                responsive={responsive}
                            >
                                <img src={Govt_Employee_1} alt="Notebook" className="media_carousel_images"/>
                                <img src={Govt_Employee_2} alt="Notebook" className="media_carousel_images"/>
                            </Carousel> 
                        </>
                    :
                        <></>
                    }
                    {
                        this.state.currentEvent === "Students_Event" ?
                        <>
                            <Carousel
                                autoPlay={true}
                                autoPlaySpeed={2500}
                                infinite={true}
                                arrows={true}
                                swipeable={false}
                                draggable={false}
                                removeArrowOnDeviceType={["mobile"]}
                                responsive={responsive}
                            >
                                <img src={Students_1} alt="Notebook" className="media_carousel_images"/>
                                <img src={Students_2} alt="Notebook" className="media_carousel_images"/>
                            </Carousel> 
                        </>
                    :
                        <></>
                    }
                    {
                        this.state.currentEvent === "SHG_Event" ?
                        <>
                            <Carousel
                                autoPlay={true}
                                autoPlaySpeed={2500}
                                infinite={true}
                                arrows={true}
                                swipeable={false}
                                draggable={false}
                                removeArrowOnDeviceType={["mobile"]}
                                responsive={responsive}
                            >
                                <img src={SHG_1} alt="Notebook" className="media_carousel_images"/>
                                <img src={SHG_2} alt="Notebook" className="media_carousel_images"/>
                            </Carousel> 
                        </>
                    :
                        <></>
                    }
                    {
                        this.state.currentEvent === "SME_Event" ?
                        <>
                            <Carousel
                                autoPlay={true}
                                autoPlaySpeed={2500}
                                infinite={true}
                                arrows={true}
                                swipeable={false}
                                draggable={false}
                                removeArrowOnDeviceType={["mobile"]}
                                responsive={responsive}
                            >
                                <img src={SME_1} alt="Notebook" className="media_carousel_images"/>
                                <img src={SME_2} alt="Notebook" className="media_carousel_images"/>
                            </Carousel> 
                        </>
                    :
                        <></>
                    }

                    {
                        this.state.currentEvent === "Kiphire_Govt_Employees" ?
                        <>
                            <Carousel
                                autoPlay={true}
                                autoPlaySpeed={2500}
                                infinite={true}
                                arrows={true}
                                swipeable={false}
                                draggable={false}
                                removeArrowOnDeviceType={["mobile"]}
                                responsive={responsive}
                            >
                                <img src={Kiphire_Govt_Employees_1} alt="Notebook" className="media_carousel_images"/>
                                <img src={Kiphire_Govt_Employees_2} alt="Notebook" className="media_carousel_images"/>
                                <img src={Kiphire_Govt_Employees_3} alt="Notebook" className="media_carousel_images"/>
                            </Carousel> 
                        </>
                    :
                        <></>
                    }
                    {
                        this.state.currentEvent === "Zunheboto_School_Event" ?
                        <>
                            <Carousel
                                autoPlay={true}
                                autoPlaySpeed={2500}
                                infinite={true}
                                arrows={true}
                                swipeable={false}
                                draggable={false}
                                removeArrowOnDeviceType={["mobile"]}
                                responsive={responsive}
                            >
                                <img src={Zunheboto_School_1} alt="Notebook" className="media_carousel_images"/>
                                <img src={Zunheboto_School_2} alt="Notebook" className="media_carousel_images"/>
                                <img src={Zunheboto_School_3} alt="Notebook" className="media_carousel_images"/>
                            </Carousel> 
                        </>
                    :
                        <></>
                    }
                    {
                        this.state.currentEvent === "Kiphire_School_Event" ?
                        <>
                            <Carousel
                                autoPlay={true}
                                autoPlaySpeed={2500}
                                infinite={true}
                                arrows={true}
                                swipeable={false}
                                draggable={false}
                                removeArrowOnDeviceType={["mobile"]}
                                responsive={responsive}
                            >
                                <img src={NGO_1} alt="Notebook" className="media_carousel_images"/>
                                <img src={NGO_2} alt="Notebook" className="media_carousel_images"/>
                            </Carousel> 
                        </>
                    :
                        <></>
                    }
                    {
                        this.state.currentEvent === "NGO_Event" ?
                        <>
                            <Carousel
                                autoPlay={true}
                                autoPlaySpeed={2500}
                                infinite={true}
                                arrows={true}
                                swipeable={false}
                                draggable={false}
                                removeArrowOnDeviceType={["mobile"]}
                                responsive={responsive}
                            >
                                <img src={Kiphire_School_1} alt="Notebook" className="media_carousel_images"/>
                                <img src={Kiphire_School_2} alt="Notebook" className="media_carousel_images"/>
                                <img src={Kiphire_School_3} alt="Notebook" className="media_carousel_images"/>
                            </Carousel> 
                        </>
                    :
                        <></>
                    }
                </div>
            </ModalBody>
        </Modal>
        <Helmet>
          <html lang="en" />  
          <meta charSet="utf-8" />
          <title>ICSAS by NIELIT - Initiative for Cyber Security Awareness Society | Media</title>
          <meta name="description" content="Initiative for Cyber Security Aware Society in NE States (ICSAS) - Under the project, 288 awareness workshops will be conducted across Nagaland, Mizoram and Sikkim." />
          <link rel="canonical" href="https://nielit-icsas.in/" />
        </Helmet>
        <div className="media_div_1">
          <div className="dashbaord_div_1_inner">
            <p className="dashbaord_div_1_inner_txt">Initiative for Cyber Security<br/>Aware Society in NE States</p>
            <p className="home_box_1_para_white">The project "Initiative for Cyber Security Aware Society in NE States (ICSAS)" was commenced in the year 2021.<br/>Under the project, 288 awareness workshops will be conducted across Nagaland, Mizoram and Sikkim, where the awareness campaigns will be carried out with posters, videos, programs through ASHA, CSC & VLEs workers, toolkits and social networks and Portal.</p>
            <button className="requestQuote">
              <a className="href_reports" href="#media">View Gallery</a>
            </button>
          </div>
        </div>
        <div className="mediaView" id="media">
            <p className="about_para_header_default">Images Gallery</p>
            <div className="l2r_images">
                <Row style={{marginBottom: 10}}>
                    <Col md={6} xs={12} sm={12}>
                        <div className="l2r_container" onClick={() => this.setState({currentEvent: "DIET_1_Event", imageModal: true })}>
                            <img src={DIET_Sikkim_1} alt="Notebook"/>
                            <div className="l2r_content">
                                <h3>District Institute of Education and Training (DIET)</h3>
                                <p>District Institute of Education and Training (DIET), Namchi - South Sikkim was held on 23rd Jan. 2023</p>
                            </div>
                        </div>
                    </Col>
                    <Col md={3}  xs={12} sm={12}>
                        <div className="container_small"  onClick={() => this.setState({currentEvent: "DIET_1_Event", imageModal: true })}>
                            <img src={DIET_Sikkim_2} alt="Notebook"/>
                            <div className="content_small">
                                <p>DIET attendees, South Sikkim</p>
                            </div>
                        </div>
                        <div className="container_small" onClick={() => this.setState({currentEvent: "DIET_1_Event", imageModal: true })}>
                            <img src={DIET_Sikkim_3} alt="Notebook"/>
                            <div className="content_small">
                                <p>DIET presentation, South Sikkim</p>
                            </div>
                        </div>
                    </Col>
                    <Col md={3} xs={12} sm={12}>
                        <div className="container_small" onClick={() => this.setState({currentEvent: "Kiphire_Govt_Employees", imageModal: true })}>
                            <img src={Kiphire_Govt_Employees_1} alt="Notebook"/>
                            <div className="content_small">
                                <p>Shri Daniel Meren, Scientist D, Kiphire - Nagaland</p>
                            </div>
                        </div>
                        <div className="container_small" onClick={() => this.setState({currentEvent: "Kiphire_Govt_Employees", imageModal: true })}>
                            <img src={Kiphire_Govt_Employees_2} alt="Notebook"/>
                            <div className="content_small">
                                <p>Govt Employees Workshop, Kiphire - Nagaland</p>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row style={{marginBottom: 10}}>
                <Col md={3} xs={12} sm={12}>
                        <div className="container_small" onClick={() => this.setState({currentEvent: "Zunheboto_School_Event", imageModal: true })}>
                            <img src={Zunheboto_School_1} alt="Notebook"/>
                            <div className="content_small">
                                <p>Shri Morimenba Amer, Scientist D, at Zuheboto, Nagaland</p>
                            </div>
                        </div>
                        <div className="container_small" onClick={() => this.setState({currentEvent: "Zunheboto_School_Event", imageModal: true })}>
                            <img src={Zunheboto_School_2} alt="Notebook"/>
                            <div className="content_small">
                                <p>Students Workshop at Zuheboto, Nagaland</p>
                            </div>
                        </div>
                    </Col>
                    <Col md={3} xs={12} sm={12}>
                        <div className="container_small" onClick={() => this.setState({currentEvent: "Kiphire_School_Event", imageModal: true })}>
                            <img src={Kiphire_School_3} alt="Notebook"/>
                            <div className="content_small">
                                <p>Daniel Meren, Scientist D, Students Workshop in Kiphire, Nagaland</p>
                            </div>
                        </div>
                        <div className="container_small">
                            <img src={Peren_SME} alt="Notebook"/>
                            <div className="content_small">
                                <p>SME Training, Peren - Nagaland</p>
                            </div>
                        </div>
                    </Col>
                    <Col md={6} xs={12} sm={12}>
                        <div className="l2r_container" onClick={() => this.setState({currentEvent: "Kiphire_School_Event", imageModal: true })}>
                            <img src={Kiphire_School_1} alt="Notebook"/>
                            <div className="l2r_content">
                                <h3>Workshop at Kiphire GHSS, Nagaland</h3>
                                <p>Awareness workshop for students of Kiphire GHSS, Nagaland on 20 Sept. 2022</p>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row style={{marginBottom: 10}}>
                    <Col md={6} xs={12} sm={12}>
                        <div className="l2r_container" onClick={() => this.setState({currentEvent: "SHG_Event", imageModal: true })}>
                            <img src={SHG_2} alt="Notebook"/>
                            <div className="l2r_content">
                                <h3>SHG Workshop in Aizawl, Mizoram</h3>
                                <p>One day long awareness worshop at Chaltlang PYD Hall Aizawl town, on 9th Mar 2023</p>
                            </div>
                        </div>
                    </Col>
                    <Col md={3} xs={12} sm={12}>
                        <div className="container_small"  onClick={() => this.setState({currentEvent: "SHG_Event", imageModal: true })}>
                            <img src={SHG_1} alt="Notebook"/>
                            <div className="content_small">
                                <p>One day awareness worshop for SHGs.</p>
                            </div>
                        </div>
                        <div className="container_small" onClick={() => this.setState({currentEvent: "Students_Event", imageModal: true })}>
                            <img src={Students_2} alt="Notebook"/>
                            <div className="content_small">
                                <p>Resource Person, ICFAI University</p>
                            </div>
                        </div>
                    </Col>
                    <Col md={3} xs={12} sm={12}>
                        <div className="container_small" onClick={() => this.setState({currentEvent: "SME_Event", imageModal: true })}>
                            <img src={SME_1} alt="Notebook"/>
                            <div className="content_small">
                                <p>Awareness Workshop, Aizawl, Mizoram on 27th March, 2023</p>
                            </div>
                        </div>
                        <div className="container_small" onClick={() => this.setState({currentEvent: "SME_Event", imageModal: true })}>
                            <img src={SME_2} alt="Notebook"/>
                            <div className="content_small">
                                <p>Resource Person, SME Awarness Workshop, Bethlehem Hall</p>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row style={{marginBottom: 10}}>
                <Col md={3} xs={12} sm={12}>
                        <div className="container_small" onClick={() => this.setState({currentEvent: "DIET_2_Event", imageModal: true })}>
                            <img src={DIET2_Sikkim_2} alt="Notebook"/>
                            <div className="content_small">
                                <p>DIET - Gangtok, East Sikkim was held on 18th Jan. 2023</p>
                            </div>
                        </div>
                        <div className="container_small" onClick={() => this.setState({currentEvent: "DIET_2_Event", imageModal: true })}>
                            <img src={DIET2_Sikkim_3} alt="Notebook"/>
                            <div className="content_small">
                                <p>Resource person for DIET - Gangtok</p>
                            </div>
                        </div>
                    </Col>
                    <Col md={3} xs={12} sm={12}>
                        <div className="container_small" onClick={() => this.setState({currentEvent: "Govt_Employees_Event", imageModal: true })}>
                            <img src={Govt_Employee_1} alt="Notebook"/>
                            <div className="content_small">
                                <p>Govt Employees Workshop at NIELIT Aizawl</p>
                            </div>
                        </div>
                        <div className="container_small" onClick={() => this.setState({currentEvent: "NGO_Event", imageModal: true })}>
                            <img src={NGO_1} alt="Notebook"/>
                            <div className="content_small">
                                <p>NGO Workshop at Aizawl, Luangmual YMA Hall</p>
                            </div>
                        </div>
                    </Col>
                    <Col md={6} xs={12} sm={12}>
                        <div className="l2r_container" onClick={() => this.setState({currentEvent: "Students_Event", imageModal: true })}>
                            <img src={Students_1} alt="Notebook"/>
                            <div className="l2r_content">
                                <h3>Students training at ICFAI University</h3>
                                <p>Awareness workshop for students of ICFAI University, Mizoran on 22 Feb. 2023</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
        <Footer/>
    </div>
    )
  }
}
export default withRouter(media);