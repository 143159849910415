import React from "react";
import { withRouter } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc  } from "firebase/firestore";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";

import "./privacy-policy.css";
import "react-toastify/dist/ReactToastify.css";
import Footer from "../../components/Footer/footer";

const firebaseConfig = {
    apiKey: "AIzaSyAg_UV0fIPW5T9fI5Cvu0pZuFc1IM5ugGc",
    authDomain: "icsas-5be17.firebaseapp.com",
    databaseURL: "https://icsas-5be17-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "icsas-5be17",
    storageBucket: "icsas-5be17.appspot.com",
    messagingSenderId: "10086162090",
    appId: "1:10086162090:web:dcf832c98b97a9b226fad3",
    measurementId: "G-S3BVTV1QSC"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

class account_deletion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        name: "",
        email: "",
        message: "",
        errors: {},
        count: 0
    };
    this.handleName = this.handleName.bind(this);
    this.handleEmail = this.handleEmail.bind(this);
    this.handleMessage = this.handleMessage.bind(this);
  }
  componentDidMount(){
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }
  handleName(event) {
    let errors = this.state.errors;
    let name = event.target.value.length
    if (name>1) {
      errors["name"] = null
      this.setState({ errors: errors});
    }
     else {
      errors["name"] = "Must not be empty";
      this.setState({ errors: errors});
    }
    this.setState({
      name: event.target.value,
    });
  }
  handleEmail = (event) => {
    var re = /\S+@\S+\.\S+/;
    let errors = this.state.errors;
    if(re.test(event.target.value))
    {
      errors["email"] = null
      this.setState({ errors: errors});
    }
    else{
      errors["email"] = "Must be valid email ID";
      this.setState({ errors: errors });
    }
    this.setState({
      email: event.target.value.toLowerCase(),
    });
  };
  handleMessage(event) {
    this.setState({ message: event.target.value });
  }
  sendContact = async () => {
    const today = moment();
    const {name, email, message, errors} = this.state;
    let nameWarning = this.state.errors["name"];
    let emailWarning = this.state.errors["email"];
    if(name != "" && email != ""){
      if(nameWarning == null && emailWarning == null)
      {
        toast.info("Sending ..", {
            position: "bottom-center",
            autoClose: 1200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        try {
            if(this.state.count === 0){
                const createUser = await addDoc(collection(db, "delete_requests"), {
                  created_at: today.format('Do MMMM, YYYY'),
                  name: name,
                  email: email,
                  reason: message,
                  isDeleted: false
                });
                this.setState({count: 1})
                toast.success("Deletion request submitted", {
                    position: "bottom-center",
                    autoClose: 2700,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setTimeout(() => {
                    window.location.reload()
                }, 800)
            }
        } catch (e) {
            console.error("Error adding document: ", e);
        }
      }
      else{
        toast.warn("Form Data Invalid", {
            position: "bottom-center",
            autoClose: 2700,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
      }
    }
    else{
        toast.warn("Form Data Invalid", {
            position: "bottom-center",
            autoClose: 2700,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
  };
  render() {
    return (
    <div>
      <div className="termscontainer">
        <div>
          <p className="terms-Title">Account Deletion Request</p>
          <p className="policy-Txt">The ICSAS App, developed and maintained by the National Institute of Electronics & Information Technology (NIELIT) Kohima, understands your right to data privacy concerns. Hence, we allow users to request data deletion. Kindly submit the form below to delete your account within 24-48 hours.</p>

          <Row>
            <Col md={5} xs={12} sm={12}>
                <p className="accountDeleteTxtFirst">Full Name</p>
                <input
                    className="in-cus-ct input_ messageInputAccountDelete"
                    type="text"
                    placeholder="Enter your full name"
                    enterKeyHint="next"
                    value={this.state.name}
                    onChange={this.handleName}
                    onFocus={this.handleName}
                />
                {
                    this.state.errors["name"] ? (
                    <p className="loginErrorTxt">
                        {this.state.errors["name"]}
                    </p>
                    ) : (
                    <div className="emptyErrorMargins"></div>
                    )
                }
                <p className="accountDeleteTxt">Email ID</p>
                <input
                    className="in-cus-ct input_ messageInputAccountDelete"
                    placeholder="Enter your registered email"
                    onChange={this.handleEmail}
                    onFocus={this.handleEmail}
                    value={this.state.email}
                    enterKeyHint="next"
                    type="text"
                />
                {
                    this.state.errors["email"] ? (
                    <p className="loginErrorTxt">
                        {this.state.errors["email"]}
                    </p>
                    ) : (
                    <div className="emptyErrorMargins"></div>
                    )
                }
                <p className="accountDeleteTxt">Reason for deletion</p>
                <textarea
                    className="in-cus-ct messageInput messageInputAccountDelete"
                    type="text"
                    placeholder="(Optional)"
                    rows="4"
                    value={this.state.message}
                    onFocus={this.handleMessage}
                    onChange={this.handleMessage}
                />

                <center>
                    <button className="requestQuote emptyErrorMargins" onClick={this.sendContact}>
                    Submit
                    </button>
                </center>
            </Col>
        </Row>        
          <p>If you have any further questions or require assistance, please don’t hesitate to contact our customer support team at info@nielit-icsas.in. We are here to assist you.</p>
          <p className="privacy_policy_address">National Institute of Electronics & Information Technology (NIELIT), Kohima</p>
          <p className="privacy_policy_address">High Court Junction, Merima</p>
          <p className="privacy_policy_address">Kohima - 797004, Nagaland, India</p>
          <p className="privacy_policy_address">Phone: 8794803021</p>
          <p className="privacy_policy_address">Email: info@nielit-icsas.in</p>
        </div>
      </div>
      <ToastContainer
            position="bottom-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
      />
      <Footer/>
    </div>
    
    );
  }
}

export default withRouter(account_deletion);
