import React from "react";
import { withRouter, Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Carousel from 'react-multi-carousel';
import { FaPhoneAlt } from "react-icons/fa";
import { IoIosMail } from "react-icons/io";
import { TfiLocationPin } from "react-icons/tfi";
import { BsCalendarDate } from "react-icons/bs";
import Fade from 'react-reveal/Fade';
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import {Helmet} from "react-helmet";
import moment from 'moment';

import MapComponent from './mapComponent'
import study from "../../assets/study.png"
import course from "../../assets/course.png"
import games from "../../assets/games.png"
import certificate from "../../assets/certificate.png"

import Slider1 from "../../assets/slider_1.jpg"
import Slider2 from "../../assets/slider_2.jpg"
import Slider3 from "../../assets/slider_3.jpg"
import Slider4 from "../../assets/slider_4.jpg"
import training from "../../assets/icon/training.png"
import workshop from "../../assets/icon/workshop.png"
import trained from "../../assets/icon/trained.png"
import pin from "../../assets/icon/pin.png"
import testimonial_1 from "../../assets/testimonial_1.jpeg"
import testimonial_2 from "../../assets/testimonial_2.jpeg"
import testimonial_3 from "../../assets/testimonial_3.jpeg"
import testimonial_4 from "../../assets/testimonial_4.jpeg"
import testimonial_5 from "../../assets/testimonial_5.jpeg"
import testimonial_6 from "../../assets/testimonial_6.jpeg"
import testimonial_7 from "../../assets/testimonial_7.jpeg"
import testimonial_8 from "../../assets/testimonial_8.jpeg"
import testimonial_9 from "../../assets/testimonial_9.jpeg"
import top_badge from "../../assets/top_badge.png"

import 'react-multi-carousel/lib/styles.css';
import "./home.css";
import "./home.scss";
import Footer from "../../components/Footer/footer";
import "react-toastify/dist/ReactToastify.css";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    paritialVisibilityGutter: 0
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    paritialVisibilityGutter: 0
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    paritialVisibilityGutter: 0
  }
};
const bannerResponsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    paritialVisibilityGutter: 0
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    paritialVisibilityGutter: 0
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    paritialVisibilityGutter: 0
  }
};

class home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      message: "",
      errors: {},
      eventsData: [],
    };
    this.handleName = this.handleName.bind(this);
    this.handleEmail = this.handleEmail.bind(this);
    this.handleMessage = this.handleMessage.bind(this);
    this.sendContact = this.sendContact.bind(this);
    this.toastFunct = this.toastFunct.bind(this);
    this.successToast = this.successToast.bind(this);
    this.warnToast = this.warnToast.bind(this);
  }
  componentDidMount(){
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    this.getEventsData();
  }
  handleName(event) {
    let errors = this.state.errors;
    let name = event.target.value.length
    if (name>1) {
      errors["name"] = null
      this.setState({ errors: errors});
    }
     else {
      errors["name"] = "Must not be empty";
      this.setState({ errors: errors});
    }
    this.setState({
      name: event.target.value,
    });
  }
  handleEmail = (event) => {
    var re = /\S+@\S+\.\S+/;
    let errors = this.state.errors;
    if(re.test(event.target.value))
    {
      errors["email"] = null
      this.setState({ errors: errors});
    }
    else{
      errors["email"] = "Must be valid email ID";
      this.setState({ errors: errors });
    }
    this.setState({
      email: event.target.value.toLowerCase(),
    });
  };
  handleMessage(event) {
    let errors = this.state.errors;
    let message = event.target.value.length
    if (message>14) {
      errors["message"] = null
      this.setState({ errors: errors});
    }
     else {
      errors["message"] = "Must be at least 15 characters";
      this.setState({ errors: errors});
    }
    this.setState({ message: event.target.value });
  }
  sendContact = async () => {
    const {name, phone, message, errors} = this.state;
    let nameWarning = this.state.errors["name"];
    let phoneWarning = this.state.errors["phone"];
    let messageWarning = this.state.errors["message"];
    if(name != "" && phone != "" && message != ""){
      if(nameWarning == null && phoneWarning == null && messageWarning == null)
      {
        this.toastFunct();
        let apiUrl = "https://nielit-icsas.in/api/add_helpdesk.php";
        let formData = new FormData();
        formData.append("name", this.state.name);
        formData.append("email", this.state.email);
        formData.append("message", this.state.message);
        formData.append("type", "via MIS Portal");
        await axios({
          method: "post",
          url: apiUrl,
          data: formData,
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.Status === "Helpdesk Added Successfully") {
            this.successToast();
          }
          this.setState({
            name: "",
            email: "",
            message: ""
          })
        })
        .catch(function (error) {
          console.log(error);
        });
      }
      else{
        this.warnToast();
        console.log("Form Invalid")
      }
    }
    else{
      this.warnToast();
      console.log("Form Invalid")
    }
  };
  toastFunct() {
    toast.info("Sending ..", {
      position: "bottom-center",
      autoClose: 1200,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  successToast() {
    toast.success("Inquiry submitted successfully", {
      position: "bottom-center",
      autoClose: 2700,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  warnToast() {
    toast.warn("Form Data Invalid", {
      position: "bottom-center",
      autoClose: 2700,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  getEventsData = async () => {
    await fetch(`https://nielit-icsas.in/api/get_events.php`, {
        method: 'GET',
      })
    .then((response) => response.json())
    .then((responseJson) => {
        if(responseJson === null){}
        else{
            let responseData = responseJson;
            responseData.reverse();
            this.setState({
                eventsData: responseData,
            });
            console.log(responseData)
        }
    })
    .catch((error) => {
        console.log(error);
    });
  }
  renderTime = (item) => {
    const timestamp = item.event_date_time;
    const date = moment(timestamp);
    const formattedDate = date.format('h A, Do MMMM YYYY');
    return(
      <span>{formattedDate}</span>
    )
  }
  render() {
    return (
      <div className="container-box">
          <Helmet>
            <html lang="en" />  
            <meta charSet="utf-8" />
            <title>ICSAS by NIELIT - Initiative for Cyber Security Awareness Society</title>
            <meta name="description" content="Initiative for Cyber Security Aware Society in NE States (ICSAS) - Under the project, 288 awareness workshops will be conducted across Nagaland, Mizoram and Sikkim." />
            <link rel="canonical" href="https://nielit-icsas.in/" />
          </Helmet>
          <Carousel
              autoPlay={true}
              autoPlaySpeed={3500}
              infinite={true}
              arrows={true}
              swipeable={false}
              draggable={false}
              removeArrowOnDeviceType={["mobile"]}
              itemClass="image-item"
              responsive={bannerResponsive}
          >
              <img src={Slider1} className="slider-img"/>
              <img src={Slider2} className="slider-img"/>
              <img src={Slider3} className="slider-img"/>
              <img src={Slider4} className="slider-img"/>
          </Carousel>
          <div className="home-content-body">
            <Row>
                <Col md={3} xs={12} sm={12}>
                  <div className='cards'>
                    <div className="card">
                      <div className="card-body">
                        <img src={study}/>
                        <h2>Learn CyberSec Topics</h2>
                        <p className="careCards_txt">ICSAS portal is a comprehensive platform for learning cyber security awareness topics through various resources, such as videos, articles, do & dont's. Users can learn about all popular cyber security trends & threats.</p>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={3} xs={12} sm={12}>
                  <div className='cards'>
                    <div className="card">
                      <div className="card-body-2">
                        <img src={certificate}/>
                        <h2>Get Certificates</h2>
                        <p className="careCards_txt">ICSAS website provides certificates to users upon the completion of courses and participation in events, which can be used to showcase their knowledge and skills in the field of cyber security awarness.</p>
                      </div>
                    </div>
                  </div> 
                </Col>
                <Col md={3} xs={12} sm={12}>
                  <div className='cards'>
                    <div className="card">
                      <div className="card-body-3">
                        <img src={games}/>
                        <h2>Play Mini-Games</h2>
                        <p className="careCards_txt">ICSAS website offers interactive mini-games such as jigsaw puzzles, crosswords, quizzes, and word searches based on cybersecurity topics, which makes learning fun and helps users gain knowledge while being engaged.</p>
                      </div>
                    </div>
                  </div> 
                </Col>
                <Col md={3} xs={12} sm={12}>
                  <div className='cards'>
                    <div className="card">
                      <div className="card-body-4">
                        <img src={course}/>
                        <h2>Attend Awarness Events</h2>
                        <p className="careCards_txt">ICSAS users can attend cybersecurity events and workshops near their location to become cyber security aware. Participation certificates are awarded upon completion of quiz.</p>
                      </div>
                    </div>
                  </div> 
                </Col>
              </Row>
            <Row style={{marginTop: "2%"}}>
              <Col md={8} xs={12} sm={12}>
                <p className="home_box_1_title">Initiative for Cyber Security Aware Society in NE States</p>
                <p className="home_box_1_title2">To be Implented by NIELIT Kohima. Funded by R&D Cyber Security Division of MeitY, Govt. of India</p>
                <p className="home_box_1_para">
                  As securing cyberspace goes beyond implementing technical measures, the first & foremost need is creating a knowledgeable society to make secure choices while being active online. Creating cyber security awareness & education is central to any attempt to secure cyberspace. As the dependence of Northeast citizens on cyberspace is fast catching up, with smartphones playing a major role, it is proposed to prepare the society at large spreading with the knowledge & skills to be able to at the least manage their security online. The major aim of the project is equipping the general public with the ability to recognize & avoid online risk.<br/><br/>Keeping this in the mind as part of this project, NIELIT, Kohima, will conduct awareness workshops in 3 states Nagaland, Mizoram and Sikkim, where awareness campaigns will be carried out with posters, videos, programs, blogs, sub-campaigns through ASHA, CSC, VLEs workers, toolkits and social networks, Portal, 24*7 helplines. The material for the campaign would be designed with apt cyber security/risk-related messages and advisories in local languages of the States to create maximum impact on the layman users among the public.
                </p>
                <Link to="/about">
                  <button className="requestQuote">
                    Know more
                  </button>
                </Link>
              </Col>
              <Col md={4} xs={12} sm={12}>
                <div className="home-content-body_right">
                  <img src={top_badge} className="home-content-body_right_img"/>
                  <br clear="all" />
                  <div className="home-content-body_right_inner scroll">
                    
                    {
                      this.state.eventsData.map((item, key) =>(
                        <div>
                          <p className="home-content-body_right_inner_header">{item.event_name}</p>
                          <Row>
                            <Col md={1} xs={1} sm={1}>
                              <TfiLocationPin
                                size={22}
                              />
                            </Col>
                            <Col md={11} xs={9} sm={9} style={{marginTop: 1}}>
                              <span>{item.venue}</span>
                            </Col>
                          </Row>
                          <Row style={{marginTop: 5}}>
                            <Col md={1} xs={1} sm={1}>
                              <BsCalendarDate
                                size={20}
                              />
                            </Col>
                            <Col xs={11} sm={11} style={{marginTop: 2}}>
                              {this.renderTime(item)}
                            </Col>
                          </Row>
                          {
                            key!=this.state.eventsData.length-1 ?
                            <hr/>
                            :
                            <></>
                          }
                        </div>
                      ))
                    }
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="home-content-body_2">
            <Fade bottom duration={800} cascade>
              <div className="home-content-body_2_inner1">
                <p className="home_box_1_title_white">Ongoing Status</p>
                <p className="home_box_1_para_white">The project "Initiative for Cyber Security Aware Society in NE States" was commenced in the year 2021 across Nagaland, Mizoram & Sikkim. Workshops for each of the categories is organised on monthly basis, which is empowering the people to be Cyber Security Aware Citizens. The project is funded by R&D Cyber Security Division of MeitY, Govt. of India.</p>
                <div className="badges-div">
                  <Row>
                    <Col md={6} sm={6} xs={6}>
                      <center>
                        <img src={pin} style={{height: 65, width: 65}}/>
                        <p className="badge-txt">Across 3 States</p>
                      </center>
                    </Col>
                    <Col md={6} sm={6} xs={6}>
                      <center>
                        <img src={workshop} style={{height: 65, width: 65}}/>
                        <p className="badge-txt">7245 Participants</p>
                      </center>
                    </Col>
                  </Row>
                  <Row style={{marginTop: 20}}>
                    <Col md={6} sm={6} xs={6}>
                      <center>
                        <img src={training} style={{height: 65, width: 65}}/>
                        <p className="badge-txt">112 Workshops Held</p>
                      </center>
                    </Col>
                    <Col md={6} sm={6} xs={6}>
                      <center>
                        <img src={trained} style={{height: 65, width: 65}}/>
                        <p className="badge-txt">6 Categories</p>
                      </center>
                    </Col>
                  </Row>
                </div>
              </div>
            </Fade>
          </div>
          <div className="home-content-body_3">
            <center>
              <p className="home-content-body_3_text">Testimonial</p> 
              <p className="testimonial-subTitle">What our trannies have to say about the awareness workshops being conducted</p>
              <div className="testimonial_div">
                <Carousel
                  autoPlay={true}
                  autoPlaySpeed={2500}
                  infinite={true}
                  arrows={false}
                  swipeable={false}
                  draggable={false}
                  itemClass="image-item"
                  responsive={responsive}
                >
                  <div className="testimonial_box">
                    <img src={testimonial_1} className="testimonialImg"/>
                  </div>
                  <div className="testimonial_box">
                    <img src={testimonial_2} className="testimonialImg"/>
                  </div>
                  <div className="testimonial_box">
                    <img src={testimonial_4} className="testimonialImg"/>
                  </div>
                  <div className="testimonial_box">
                    <img src={testimonial_3} className="testimonialImg"/>
                  </div>
                  <div className="testimonial_box">
                    <img src={testimonial_5} className="testimonialImg"/>
                  </div>
                  <div className="testimonial_box">
                    <img src={testimonial_6} className="testimonialImg"/>
                  </div>
                  <div className="testimonial_box">
                    <img src={testimonial_7} className="testimonialImg"/>
                  </div>
                  <div className="testimonial_box">
                    <img src={testimonial_8} className="testimonialImg"/>
                  </div>
                  <div className="testimonial_box">
                    <img src={testimonial_9} className="testimonialImg"/>
                  </div>
                </Carousel>
              </div>
            </center>
          </div>
          <div className="home-content-body_4">
            <Row>
              <Col md={6} xs={12} sm={12}>
                <MapComponent/>
              </Col>
              <Col md={6} xs={12} sm={12}>
                <Fade bottom duration={800} cascade>
                  <div className="home-content-body_4_inner1">
                    <p className="home_box_1_title_white">Helpdesk</p>
                    <p className="home_box_1_para_white">Fill up the form and our team will get back to you</p>
                    <div className="contact-icon-first-home">
                      <Row>
                        <Col md={1} xs={2} sm={2}>
                          <FaPhoneAlt
                            size={22}
                            className="loc-icons"
                          />
                        </Col>
                        <Col md={5} xs={10} sm={10}>
                          <a href={"tel:" + "+919436215243"}>
                            <p className="contact-div1-subtitle-home number">+91-94362-15243</p>
                          </a>
                        </Col>
                      </Row>
                    </div>
                    <div className="contact-icon-second-home">
                      <Row>
                        <Col md={1} xs={2} sm={2}>
                          <IoIosMail
                            size={28}
                            className="loc-icons"
                          />
                        </Col>
                        <Col md={4} xs={10} sm={10}>
                          <p className="contact-div1-subtitle-home number" onClick={() => window.location = 'mailto:info@nielit-icsas.in'}>info@nielit-icsas.in</p>
                        </Col>
                      </Row>
                    </div>
                    <input
                      className="in-cus-ct input_"
                      type="text"
                      placeholder="Full Name"
                      enterKeyHint="next"
                      value={this.state.name}
                      onChange={this.handleName}
                      onFocus={this.handleName}
                    />
                    {
                      this.state.errors["name"] ? (
                        <p className="loginErrorTxt">
                          {this.state.errors["name"]}
                        </p>
                      ) : (
                        <div className="emptyErrorMargins"></div>
                      )
                    }
                    <input
                      className="in-cus-ct input_"
                      placeholder="Email ID"
                      onChange={this.handleEmail}
                      onFocus={this.handleEmail}
                      value={this.state.email}
                      enterKeyHint="next"
                      type="text"
                    />
                    {
                      this.state.errors["email"] ? (
                        <p className="loginErrorTxt">
                          {this.state.errors["email"]}
                        </p>
                      ) : (
                        <div className="emptyErrorMargins"></div>
                      )
                    }
                    <textarea
                      className="in-cus-ct messageInput"
                      type="text"
                      placeholder="Message"
                      rows="4"
                      value={this.state.message}
                      onFocus={this.handleMessage}
                      onChange={this.handleMessage}
                    />
                    {
                      this.state.errors["message"] ? (
                        <p className="loginErrorTxt">
                          {this.state.errors["message"]}
                        </p>
                      ) : (
                        <div style={{marginBottom: "3%"}}></div>
                      )
                    }
                    <center>
                      <button className="requestQuote" onClick={this.sendContact}>
                        Submit
                      </button>
                    </center>
                    
                  </div>
                </Fade>
              </Col>
            </Row>
            <ToastContainer
              position="bottom-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </div>
          <Footer/>
      </div>
    )
  }
}
export default withRouter(home);
