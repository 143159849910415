import React from "react";
import { withRouter, Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Fade from 'react-reveal/Fade';
import {Helmet} from "react-helmet";

import aim from "../../assets/aim.png"
import location from "../../assets/location.png"
import time from "../../assets/time.png"
import "./about.css";
import Footer from "../../components/Footer/footer";


const tabStyle = {
    row: {
        marginLeft: 0,
        marginRight: 0
    },
    col: {
        paddingLeft: 0,
        paddingRight: 0
    }
};
  

class about extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      projectView: true,
      targetView: false,
      deliverableView: false,
      aboutView: false
      
    };
  }
  componentDidMount(){
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }
  render() {
    return (
      <div>
        <Helmet>
          <html lang="en" />  
          <meta charSet="utf-8" />
          <title>ICSAS by NIELIT - Initiative for Cyber Security Awareness Society | About</title>
          <meta name="description" content="Initiative for Cyber Security Aware Society in NE States (ICSAS) - Under the project, 288 awareness workshops will be conducted across Nagaland, Mizoram and Sikkim." />
          <link rel="canonical" href="https://nielit-icsas.in/" />
        </Helmet>
        <div className="about_div_1">
          <div className="dashbaord_div_1_inner">
          <p className="dashbaord_div_1_inner_txt">Initiative for Cyber Security Aware Society in NE States</p>
            <p className="home_box_1_para_white">The project "Initiative for Cyber Security Aware Society in NE States (ICSAS)" was commenced in the year 2021.<br/>Under the project, 288 awareness workshops will be conducted across Nagaland, Mizoram and Sikkim, where the awareness campaigns will be carried out with posters, videos, programs through ASHA, CSC & VLEs workers, toolkits and social networks and Portal.</p>
            <button className="requestQuote">
              <a className="href_reports" href="#tabs">Know More</a>
            </button>
          </div>
        </div>
        <div className="about_tabs" id="tabs">
            <Row style={tabStyle.row}>
                <Col md={2} xs={6} sm={6}>
                    {
                        this.state.projectView ?
                            <div className="about_tabs_active">
                                <span className="about_tabs_active_txt">Project Info</span>
                            </div>
                        :
                        <div className="about_tabs_inactive" onClick={() => this.setState({projectView: true, targetView: false, deliverableView: false, aboutView: false})}>
                            <span className="about_tabs_inactive_txt">Project Info</span>
                        </div>
                    }
                </Col>
                <Col md={2} xs={6} sm={6}>
                    {
                        this.state.targetView ?
                            <div className="about_tabs_active">
                                <span className="about_tabs_active_txt">Targets</span>
                            </div>
                        :
                        <div className="about_tabs_inactive" onClick={() => this.setState({projectView: false, targetView: true, deliverableView: false, aboutView: false})}>
                            <span className="about_tabs_inactive_txt">Targets</span>
                        </div>
                    }
                </Col>
                <Col md={2} xs={6} sm={6}>
                    {
                        this.state.deliverableView ?
                            <div className="about_tabs_active">
                                <span className="about_tabs_active_txt">Deliverables</span>
                            </div>
                        :
                        <div className="about_tabs_inactive" onClick={() => this.setState({projectView: false, targetView: false, deliverableView: true, aboutView: false})}>
                            <span className="about_tabs_inactive_txt">Deliverables</span>
                        </div>
                    }
                </Col>
                <Col md={2} xs={6} sm={6}>
                    {
                        this.state.aboutView ?
                            <div className="about_tabs_active">
                                <span className="about_tabs_active_txt">About NIELIT</span>
                            </div>
                        :
                        <div className="about_tabs_inactive" onClick={() => this.setState({projectView: false, targetView: false, deliverableView: false, aboutView: true})}>
                            <span className="about_tabs_inactive_txt">About NIELIT</span>
                        </div>
                    }
                </Col>
                <Col></Col>
            </Row>
        </div>
        {
            this.state.targetView ?
                <div className="targets_View">
                    <table className="targetsTable">
                        <thead>
                            <tr>
                                <th>Sl. No</th>
                                <th>Training Programs</th>
                                <th>Nagaland</th>
                                <th>Mizoram</th>
                                <th>Sikkim</th>
                                <th>Total Workshops</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td className="boldText">District HQs, Govt Middle Level Officials</td>
                                <td>12</td>
                                <td>11</td>
                                <td>6</td>
                                <td>29</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>
                                    <table className="paleBlueRows">
                                        <tbody>
                                            <tr>
                                                <td className="boldText">Awareness intermediatary</td>
                                            </tr>
                                            <tr>
                                                <td>a) CSCs/VLEs</td>
                                            </tr>
                                            <tr>
                                                <td>b) ASHA workers</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                                <td>18</td>
                                <td>14</td>
                                <td>5</td>
                                <td>37</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td className="boldText">Local Muncipalities, Town/Village Council</td>
                                <td>12</td>
                                <td>11</td>
                                <td>8</td>
                                <td>31</td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td className="boldText">Self Help Group (SHG)</td>
                                <td>21</td>
                                <td>20</td>
                                <td>12</td>
                                <td>53</td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td className="boldText">Small & Medium Entreprise (SMEs)</td>
                                <td>20</td>
                                <td>19</td>
                                <td>12</td>
                                <td>51</td>
                            </tr>
                            <tr>
                                <td>6</td>
                                <td className="boldText">Citizens & General Public (NGOs, Elderly Cistizens etc)</td>
                                <td>16</td>
                                <td>14</td>
                                <td>8</td>
                                <td>38</td>
                            </tr>
                            <tr>
                                <td>7</td>
                                <td className="boldText">School/College students, ITI's</td>
                                <td>15</td>
                                <td>15</td>
                                <td>10</td>
                                <td>40</td>
                            </tr>
                            <tr>
                                <td>8</td>
                                <td className="boldText">State Level Cyber Security Expo cum Conference</td>
                                <td>3</td>
                                <td>3</td>
                                <td>3</td>
                                <td>9</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>Total</td>
                                <td>117</td>
                                <td>107</td>
                                <td>64</td>
                                <td><b>288</b></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            : 
                <></>
        }
        {
            this.state.projectView ?
                <div className="targets_View">
                    <Fade bottom>
                        <p className="about_para_title"><mark> Initiative for Cyber Security Aware Society in NE States </mark></p>
                        <p className="about_para_subtitle">The widespread use of smartphones has brought positive changes to communication, business, and government, but it also poses many risks, particularly in the form of cybercrime. Under the project ICSAS, NIELIT Kohima will promote cyber security awareness to ensure that people are well-informed and able to protect themselves. In Northeastern States, there is a lack of such initiatives, and a need has been felt to create a mass awareness program covering all sections of society due to geographical remoteness.</p>
                        <hr/>
                        <div className="breaker"/>
                        <img src={aim} className="about_icons"></img>
                        <span className="about_para_header">Aim & Objectives</span>
                        <br clear="all" /><br/>
                        <p className="about_para_subtitle">It has been recognised worldwide that securing cyber space goes beyond implementing technical measures, the first and foremost need being creating a knowledgeable society to make secure choices while active online. Creating cyber security awareness and education is central to any attempt to secure cyberspace. As dependence of North east citizens on cyber space is fast catching up, with smart phones playing a major role, it is proposed to prepare the society at large spreading across various sections with the knowledge and skills to be able to at the least manage their own security while going online.</p>
                        <p className="about_para_subtitle">The aim is to create security cyber citizens who are well aware of the nature of cyber space and the risks that come with the lifestyle that is highly interwoven into cyber space. The major aim of the project equipping the general public with the ability to recognise to avoid online risk.</p>
                    </Fade>
                    <ul>
                        <li>Raise comprehensive complete awareness about risk in cyber space targeting different sections of society by designing and conducting audience specific campaigns to create impact so that people before connecting to online world understand the risk and vulnerabilities in cyber space and would be in a position to make an informed choice.</li>
                        <li>Promoting the use of cyber security resources and tools for carrying out a number of awareness programs with the aim to encourage participation in cyber security focussed activities in each district of the three states viz: Nagaland, Mizoram and Sikkim.</li>
                        <li>Development of "Cybersmart Citizen" and "Smart Stay Online" campaigns catering to different sections of the society. The segments targeted would include general public (SHG/NGOs/CSC/VLEs/ASHA workers etc), students, undergraduate student, young professionals, including elderly citizens, government and SMEs.</li>
                    </ul>
                    <div className="breaker"/>
                    <hr/>
                    <div className="breaker"/>
                    <Fade bottom>
                        <img src={location} className="about_icons"></img>
                        <span className="about_para_header">Location of the project</span>
                        <br clear="all" /><br/>
                        <p className="about_para_subtitle">All 27 districts in the States of Nagaland, Mizoram and Sikkim will be covered.</p>
                        <hr/>
                        <div className="breaker"/>
                        <img src={time} className="about_icons"></img>
                        <span className="about_para_header">Duration of the project</span>
                        <br clear="all" /><br/>
                        <p className="about_para_subtitle">The tenure of the Project will be 36 Months as per the project proposal.</p>
                    </Fade>
                </div>
            :
                <></>
        }
        {
            this.state.deliverableView ? 
                <div className="targets_View">
                    <p className="about_para_header_default">Deliverables under the project</p>
                    <Fade bottom cascade>
                        <ul>
                            <li>All 27 districts in the States of Nagaland, Mizoram and Sikkim will be covered. Indirectly large number of citizens will be cyber security aware in all the 3 States.</li>
                            <li>Availability of cyber security awareness information from various sources including ISEA project of Meity will be compiled, create and redesign/translate in 16 local languages/dialects of the 3 States.</li>
                            <li>Creation of new audio/video with appropriate impactful content in 16 local Languages/dialects targeting each section of society</li>
                            <li>288 Workshops will be conducted spread across all districts targeting different section of society
                                <ol>
                                    <li>Middle level Govt officials at District HQs : 29 workshops and 1160 participants</li>
                                    <li>Awareness Intermediary CSCs/VLEs : 18 workshops and 360 participants</li>
                                    <li>Awareness Intermediary Asha workers : 19 workshops and 380 participants</li>
                                    <li>Local Municipalities, Town/Village Council : 31 workshops and 1240 participants</li>
                                    <li>Self Help Group (SHG) : 53 workshops and 2120 participants </li>
                                    <li>Small & Medium Enterprise (SMEs) : 51 workshops and 2040 participants</li>
                                    <li>Citizens and general public (NGOs, elderly cistizens etc) : 38 and 1520 participants</li>
                                    <li>School/College students, ITI's : 40 and 4000 participants</li>
                                    <li>State Level Cyber Security expo cum Conference : 9</li>
                                </ol>
                            </li>
                            <li>740 CSC/VLEs and Asha workers, in the villages/towns will be mentored with proper training in their language of understanding as Awareness Intermediary who in turn will disseminate information about cyber aware / ethics online. They are expected to make cyber security aware of 30000 citizens in remote parts of the States </li>
                            <li>Web Portal & MIS to disseminate the information on cyber security awareness and secure use of Internet and securing PC to the general masses will be set up. Cyber security assistance will be provided through web portal, emails and helpline.</li>
                            <li>To create maximum awareness & impact among the masses on cyber security, online/social media/video conference campaign will be carried out apart from offline organizing of events</li>
                            <li>Explore the possibilities of automating the translation of cyber security information advisories & Do's and Dont's to few local language scripts using some of the existing tools</li>
                        </ul>
                    </Fade>
                </div>
            :
                <></>
        }
        {
            this.state.aboutView ? 
                <div className="targets_View">
                    <p className="about_para_header_default">About NIELIT Kohima</p>
                    <Fade bottom>
                        <div>
                            <p className="about_para_subtitle">National Institute of Electronics & Information Technology (NIELIT), Kohima with its stately architecture surrounded by lush verdant and picturesque rolling hills has world-class infrastructure that facilitates excellence in IECT-related teaching, training and other professional activities, besides involving in Research & Development work, Consultancy services and Software Development. It is a national level technical Institute under NIELIT, an autonomous scientific body of the Ministry of Communications & IT, Govt. of India set up in the year 2004.</p>
                            <p className="about_para_subtitle">NIELIT Kohima also aspires to be a hub of innovative activity and is continuously on the lookout for opportunities to collaborate with organisations in government, the public and private sectors and other academic institutions to create, disseminate and apply computer knowledge and practices. NIELIT as an institute offers training program to improve employment opportunities and facilitate availability of quality IT manpower, which will contribute to the growth of IT industry in the region particularly in Nagaland. A special feature of NIELIT is the provision of a balanced amalgamation of formal and non-formal sector courses with the aim to provide opportunities for education and training in the field of IECT.</p>
                        </div>
                    </Fade>
                    <div className="breaker"/>
                    <Fade bottom>
                        <p className="about_para_header_default">About NIELIT Aizawl</p>
                        <p className="about_para_subtitle">NIELIT Aizawl (Formerly DOEACC Centre, Aizawl) established in the year 2001 and located at Industrial Estate, Zuangtui in the northern direction from the heart of the capital city of Mizoram.</p>
                        <p className="about_para_subtitle">NIELIT Aizawl specializes in development of skill in the fields of Information, Electronics and Communication Technology (IECT) through various long term and short term courses. The long term courses namely the Diploma in Computer Science & Engg.(DCSE), Diploma in Electronics and Telecommunication Engg. (DETE), Bachelor of Computer Application (BCA), and Master of Computer Application (MCA) are recognized professional courses which fall in formal academic sector. Apart from this, the Centre also offers various NIELIT courses such as NIELIT ‘A’ LEVEL, ‘O’LEVEL, MAT ‘O’ etc. aimed at giving proficiency and the hands-on skill in the key areas of technology.</p>
                    </Fade>
                    <div className="breaker"/>
                    <Fade bottom>
                        <p className="about_para_header_default">About NIELIT Gangtok</p>
                        <p className="about_para_subtitle">NIELIT Gangtok established in the year 2010 and located at Indira Bypass road, Sichey, Gangtok.</p>
                        <p className="about_para_subtitle">NIELIT, Gangtok is established with a prime objective of creating specialized manpower in the areas of Information Electronics and Communication Technologies (IECT). The Centre offers training & educational services to improve the employment opportunities and facilitate the availability of quality manpower at affordable cost, which in turn will lead to further growth of IECT Industry in the region.</p>
                    </Fade>
                </div>
            :
                <></>
        }
        <Footer/>
      </div>
    )
  }
}
export default withRouter(about);