import React from "react";
import { withRouter } from "react-router-dom";

import "./privacy-policy.css";
import Footer from "../../components/Footer/footer";

class privacy_policy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFABOpen: false
    };
  }
  componentDidMount(){
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }
  render() {
    return (
    <div>
      <div className="termscontainer">
        <div>
          <p className="terms-Title">Privacy Policy</p>
          <p className="policy-Txt">The ICSAS App is developed and maintained by the National Institute of Electronics & Information Technology (NIELIT) Kohima, with a primary focus on providing users with a comprehensive educational platform for cyber security awareness. This Privacy Policy outlines our practices regarding the collection, use, and disclosure of personal information when using the ICSAS App.</p>
          <p className="policy-SubTitle">1. Information Collection and Use:</p>
          <ul>
            <li>
                <p>Personal Information: We collect and store personal information such as name, email address, and contact number for user registration and communication purposes.</p>
            </li>
            <li>
                <p>Location Data: The app may request access to your device's location for statistical purposes and to provide relevant nearby services, such as locating the nearest police station. Location data will only be used for internal purposes and will not be shared with third parties.</p>
            </li>
            <li>
                <p>File Permissions: The app may request access to your device's files to upload profile pictures and download e-Certificates. This access is necessary for the proper functioning of these features and is limited to the intended purpose only.</p>
            </li>
          </ul>

          <p className="policy-SubTitle">2. Information Security:</p>
          <ul>
            <li>
                <p>Data Storage: Personal information collected through the app is securely stored on cloud servers. We implement industry-standard security measures to protect against unauthorized access, disclosure, alteration, or destruction of your personal information.</p>
            </li>
            <li>
                <p>We restrict access to your personal information to authorized personnel who require it to perform their duties.</p>
            </li>
          </ul>

          <p className="policy-SubTitle">3. Information Sharing and Disclosure:</p>
          <ul>
            <li>
                <p>Third-Party Sharing: We do not share any personal and non-personal information collected through the app with any third party.</p>
            </li>
            <li>
                <p>Aggregated Data: We may use aggregated and anonymized data for statistical analysis and reporting purposes. This data will not personally identify any individual user.</p>
            </li>
          </ul>

          <p className="policy-SubTitle">4. Data Retention & Deletion:</p>
          <ul>
            <li>
                <p>All your personal and educational details are retained as long as you use ICSAS App.</p>
            </li>
            <li>
                <p>All your data will be deleted if you request to delete your account.</p>
            </li>
          </ul>

          <p className="policy-SubTitle">5. How We Use Your Information:</p>
          <ul>
            <li>
                <p>To provide and improve our app and its features.</p>
            </li>
            <li>
                <p>To personalize your experience and tailor content to your interests.</p>
            </li>
            <li>
                <p>To analyze usage trends and improve the overall user experience.</p>
            </li>
          </ul>

          <p className="policy-SubTitle">6. Changes to the Privacy Policy: <span className="policy-spanSubTitle">We reserve the right to modify or update this privacy policy at any time. We will notify you of any material changes through prominent notices on our website.</span></p>
          <p className="policy-SubTitle">7. Contact Us: <span className="policy-spanSubTitle">If you have any questions, concerns, or requests regarding our privacy policy or the handling of your personal information, please contact us using the provided contact information.</span></p>
          <p>Last updated: 31/05/2024</p>
          <p>If you have any further questions or require assistance, please don’t hesitate to contact our customer support team at info@nielit-icsas.in. We are here to assist you.</p>
          <p className="privacy_policy_address">National Institute of Electronics & Information Technology (NIELIT), Kohima</p>
          <p className="privacy_policy_address">High Court Junction, Merima</p>
          <p className="privacy_policy_address">Kohima - 797004, Nagaland, India</p>
          <p className="privacy_policy_address">Phone: 8794803021</p>
          <p className="privacy_policy_address">Email: info@nielit-icsas.in</p>
        </div>
      </div>
      <Footer/>
    </div>
    
    );
  }
}

export default withRouter(privacy_policy);
