import React from "react";
import { withRouter } from "react-router-dom";
import "./footer.css";
import { Link } from "react-router-dom";
import facebook from "../../assets/facebook.png";
import twitter from "../../assets/twitter.png";
import instagram from "../../assets/instagram.png";
import youtube from "../../assets/youtube.png";
import playstore from "../../assets/playstore.png";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class Footer extends React.Component {
  render() {
    return (
        <div className="footer_contant">
            <Row>
                <Col xs={12} sm={12} md={4} lg={4}>
                    <div className="items-cls">
                        <p className="footer_col_title">Get in touch</p>
                        <Row style={{width: "70%"}}>
                            <Col md={2} lg={2} xs={2} sm={2}>
                                <a href="https://www.facebook.com/KHM.NIELIT/">
                                    <img
                                        alt="logo"
                                        src={facebook}
                                        className="social-icons"
                                    />
                                </a>
                            </Col>
                            <Col md={2} lg={2} xs={2} sm={2}>
                                <a href="https://www.instagram.com/nielit_kohima">
                                    <img
                                        alt="logo"
                                        src={instagram}
                                        className="social-icons"
                                    />
                                </a>
                            </Col>
                            <Col md={2} lg={2} xs={2} sm={2}>
                                <a href="https://twitter.com/khm_nielit">
                                    <img
                                        alt="logo"
                                        src={twitter}
                                        className="social-icons"
                                    />
                                </a>
                            </Col>
                            <Col md={2} lg={2} xs={2} sm={2}>
                                <a href="https://www.youtube.com/channel/UC-U-ksaR34YRnCNWG-_6UOA">
                                    <img
                                        alt="logo"
                                        src={youtube}
                                        className="social-icons"
                                        style={{marginTop: 5}}
                                    />
                                </a>
                            </Col>
                            <Col md={4} lg={4} xs={2} sm={2}></Col>
                        </Row>
                        <img src={playstore} onClick={() => window.location = 'https://play.google.com/store/apps/details?id=com.nielitkma.icsas'} className="playstore"/>
                        <br className="counter_br"/>
                        <a><img src="https://hits.seeyoufarm.com/api/count/incr/badge.svg?url=https%3A%2F%2Fnielit-icsas.in&count_bg=%23118FEF&title_bg=%23555555&icon=codeforces.svg&icon_color=%23E7E7E7&title=++Total+Visits&edge_flat=false"/></a>
                    </div>
                </Col>
                <Col xs={12} sm={12} md={4} lg={4}>
                    <div className="items-cls sec">
                        <p className="footer_col_title">Other links</p>
                        <div className="links">
                            <a href="https://www.meity.gov.in/">
                                <p className="links links_text">MeitY, GoI</p>
                            </a>
                            <a href="https://nielit.gov.in/kohima/index.php">
                                <p className="links links_text">NIELIT Kohima</p>
                            </a>
                            <a href="https://nielit.gov.in/aizawl/index.php">
                                <p className="links links_text">NIELIT Aizawl</p>
                            </a>
                            <a href="https://nielit.gov.in/gangtok/index.php">
                                <p className="links links_text">NIELIT Gangtok</p>
                            </a>
                            <p className="links">
                                <Link className="links_text" to="/privacy-policy">Privacy Policy / Terms of Use</Link>
                            </p>
                        </div>
                    </div>
                </Col>
                <Col sm={12} md={4} lg={4}>
                    <div  className="items-cls">
                        <p className="footer_col_title">Contact</p>
                        <p className="info">
                            NIELIT Kohima Campus<br/>
                            New High Court Road Junction,<br/>
                            Merima, Kohima<br/>
                            Nagaland - 797001, IN<br/><br/>
                            Email - info@nielit-icsas.in
                        </p>
                    </div>
                </Col>
            </Row>
        </div>
    );
  }
}

export default withRouter(Footer);
